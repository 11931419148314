import React, { useState } from 'react';
import { Box, Grid, Typography, Paper, TextField, Button, MenuItem, Select, FormControl, InputLabel, Link, Snackbar, Alert } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import InstagramIcon from '@mui/icons-material/Instagram';
import { contactInfo, subjects } from '../data/ContactData';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';

const schema = z.object({
  name: z.string().min(1, 'Adınız Soyadınız zorunlu'),
  email: z.string().email('Geçerli bir e-posta adresi girin'),
  phone: z.string(),
  subject: z.string().min(1, 'Konu zorunlu'),
  message: z.string()
});

const isMobile = () => {
  return /Mobi|Android|iPhone|iPad|iPod/i.test(navigator.userAgent);
};

const ContactPage = () => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('success');

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue
  } = useForm({
    resolver: zodResolver(schema)
  });

  const onSubmit = async (data) => {
    try {
      const response = await fetch('https://polenozudogru.com/api/contact', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      });

      if (response.ok) {
        setSeverity('success');
        setMessage('Mesajınız başarıyla gönderildi!');
      } else {
        setSeverity('error');
        setMessage('Mesaj gönderilirken bir hata oluştu.');
      }
      setOpen(true);
    } catch (error) {
      console.error('Mesaj gönderilemedi:', error);
      setSeverity('error');
      setMessage('Mesaj gönderilemedi, lütfen tekrar deneyin.');
      setOpen(true);
    }
  };

  return (
    <Box>
      <Box sx={{ backgroundColor: '#cccccc', padding: '20px 0', marginTop: '100px', textAlign: 'center' }}>
        <Typography
          variant="h1"
          sx={{
            color: '#fff',
            margin: '0px',
            fontSize: '35px',
            letterSpacing: '10px',
            textTransform: 'uppercase',
            marginTop: '70px',
            fontWeight: 100
          }}
        >
          İLETİŞİM
        </Typography>
      </Box>
      <Box sx={{ backgroundColor: '#f5f5f5', padding: '2rem' }}>
        <Paper sx={{ padding: '2rem', maxWidth: '1200px', mx: 'auto', boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)' }}>
          <Grid container spacing={4} sx={{ justifyContent: 'center' }}>
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  backgroundColor: '#f0f0f0',
                  padding: '20px',
                  borderRadius: '8px',
                  color: '#333',
                  marginBottom: '20px'
                }}
              >
                <Typography variant="h6" sx={{ marginBottom: '20px', fontWeight: 'bold' }}>
                  İletişim Bilgileri
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                  {isMobile() ? (
                    <Link href={`tel:${contactInfo.phone}`} sx={{ display: 'flex', alignItems: 'center', textDecoration: 'none', color: 'inherit' }}>
                      <PhoneIcon sx={{ marginRight: '10px', color: '#333' }} />
                      <Typography>{contactInfo.phone}</Typography>
                    </Link>
                  ) : (
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <PhoneIcon sx={{ marginRight: '10px', color: '#333' }} />
                      <Typography>{contactInfo.phone}</Typography>
                    </Box>
                  )}
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                  <Link href={`mailto:${contactInfo.email}`} sx={{ display: 'flex', alignItems: 'center', textDecoration: 'none', color: 'inherit' }}>
                    <EmailIcon sx={{ marginRight: '10px', color: '#333' }} />
                    <Typography>{contactInfo.email}</Typography>
                  </Link>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
                  <Typography>{contactInfo.address}</Typography>
                </Box>
                <Typography variant="h6" sx={{ marginBottom: '20px', fontWeight: 'bold' }}>
                  Sosyal Medya
                </Typography>
                <Typography>Beni instagram'dan takip edebilirsiniz.</Typography>
                <Box sx={{ mt: 2, display: 'flex', gap: 2 }}>
                  <Link href="https://www.instagram.com" target="_blank">
                    <InstagramIcon sx={{ color: '#333', fontSize: 30 }} />
                  </Link>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h6" sx={{ mb: 2 }}>
                İletişim Formu
              </Typography>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField label="Adınız Soyadınız" {...register('name')} error={!!errors.name} helperText={errors.name?.message} fullWidth margin="normal" />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField label="E-posta Adresiniz" {...register('email')} error={!!errors.email} helperText={errors.email?.message} fullWidth margin="normal" />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField label="Telefon Numaranız" {...register('phone')} error={!!errors.phone} helperText={errors.phone?.message} fullWidth margin="normal" />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth margin="normal">
                      <InputLabel>Konu</InputLabel>
                      <Select label="Konu" {...register('subject')} defaultValue="" error={!!errors.subject} onChange={(e) => setValue('subject', e.target.value)}>
                        {subjects.map((subject) => (
                          <MenuItem key={subject.value} value={subject.label}>
                            {subject.label}
                          </MenuItem>
                        ))}
                      </Select>
                      {errors.subject && <Typography color="error">{errors.subject.message}</Typography>}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Mesajınız"
                      {...register('message')}
                      error={!!errors.message}
                      helperText={errors.message?.message}
                      fullWidth
                      margin="normal"
                      multiline
                      rows={4}
                    />
                  </Grid>
                </Grid>
                <Button type="submit" variant="contained" sx={{ marginTop: '1rem', backgroundColor: '#333', color: '#fff' }}>
                  Gönder
                </Button>
              </form>
            </Grid>
          </Grid>
        </Paper>
      </Box>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ContactPage;
