import React from 'react';
import { Box, Typography, Grid, Paper, CardActionArea } from '@mui/material';
import { Link } from 'react-router-dom';

const services = [
  { title: 'Bireysel Psikolojik Danışma', description: 'Bireysel terapiler hakkında bilgi alın.', link: '/bireysel-psikolojik-danisma', image: '/images/bireysel.png' },
  { title: 'Aile ve Evlilik Danışmanlığı', description: 'Çift terapileri hakkında bilgi alın.', link: '/cift-ve-aile-danismanligi', image: '/images/çift.jpg' },
  { title: 'Çocuk ve Ergen Danışmanlığı', description: 'Çocuk ve ergen terapileri hakkında bilgi alın.', link: '/cocuk-ergen-danismanligi', image: '/images/ergen.png' },
  { title: 'Ebeveyn Danışmanlığı', description: 'Ebeveyn danışmanlığı hakkında bilgi alın.', link: '/ebeveyn-danismanligi', image: '/images/ebeveyn.png' },
  { title: 'Sınav Danışmanlığı', description: 'Sınav danışmanlığı hakkında bilgi alın.', link: '/sinav-danismanligi', image: '/images/sınav.png' }
];
const Services = () => {
  return (
    <Box
      sx={{
        width: '100%',
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
        py: 4
      }}
    >
      <Typography variant="h4" align="center" gutterBottom>
        HİZMETLERİMİZ
      </Typography>
      <Grid container spacing={3} justifyContent="center" sx={{ padding: '0 16px' }}>
        {services.map((service, index) => (
          <Grid item key={index} xs={12} sm={6} md={4} lg={2.4}>
            <CardActionArea component={Link} to={service.link} style={{ textDecoration: 'none' }}>
              <Paper elevation={3} sx={{ padding: 2, textAlign: 'center', minHeight: '300px', boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)' }}>
                <img src={service.image} alt={service.title} style={{ width: '100%', height: 'auto' }} />
                <Box sx={{ mt: 3 }}>
                  <Typography variant="h6">{service.title}</Typography>
                  <Typography variant="body1">{service.description}</Typography>
                </Box>
              </Paper>
            </CardActionArea>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Services;
