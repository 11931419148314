import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import ServiceSlider from './components/ServiceSlider';
import Services from './components/Service';
import ChildTherapy from './pages/ourservices/ChildTherapy';
import CoupleTherapy from './pages/ourservices/CoupleTherapy';
import ExamTherapy from './pages/ourservices/ExamTherapy';
import ParentsTherapy from './pages/ourservices/ParentsTherapy';
import PersonalTherapy from './pages/ourservices/PersonalTherapy';
import Blog from './pages/Blog';
import BlogDetail from './pages/BlogDetail';
import AboutMe from './pages/AboutMe';
import Footer from './components/Footer';
import ContactPage from './pages/ContactPage';
import FAQComponent from './components/FaqComponent';

export default function App() {
  return (
    <Router>
      <div style={{ position: 'relative', minHeight: '100vh', overflow: 'auto' }}>
        <Header />
        <Routes>
          <Route
            path="/"
            element={
              <>
                <ServiceSlider />
                <Services />
                <FAQComponent />
              </>
            }
          />
          <Route path="/bireysel-psikolojik-danisma" element={<PersonalTherapy />} />
          <Route path="/cocuk-ergen-danismanligi" element={<ChildTherapy />} />
          <Route path="/cift-ve-aile-danismanligi" element={<CoupleTherapy />} />
          <Route path="/ebeveyn-danismanligi" element={<ParentsTherapy />} />
          <Route path="/sinav-danismanligi" element={<ExamTherapy />} />
          <Route path="/hakkımda" element={<AboutMe />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/:subject" element={<BlogDetail />} />
          <Route path="/iletisim" element={<ContactPage />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}
