import { Box, Typography } from '@mui/material';

const blogData = [
  {
    id: 1,
    title: 'Hayatın Kaosunda Kendinizi Bulmak: Stres Yönetimi Nasıl Yapılır?',
    subject: 'stres-yonetimi',
    image: '/images/stres.png',
    content: (
      <>
        <Typography variant="body1" sx={{ marginTop: '3.5rem', marginBottom: '1.5rem' }}>
          Hepimiz bu dünyada bir yerlerde sıkışıp kalmış gibiyiz, değil mi? Sabahları gözümüzü açtığımız an başlayan ve gece yatağa girdiğimizde bile bitmeyen bir koşuşturma.
          İşler, faturalar, ilişkiler, sağlık derken, hayat bir maraton gibi... Ve ne yazık ki, bu maratonda koşarken stres denen o görünmez yük hep omuzlarımızda. Peki, bu yükü
          hafifletmek için ne yapıyoruz? Genellikle hiçbir şey, çünkü hep "yetişmemiz gereken bir şeyler" var.
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: '1.5rem' }}>
          İşte tam bu noktada stres yönetimi devreye giriyor. Stresle başa çıkmak, aslında bu maratonda nefes alabilmek için bir mola vermektir. Bir an durup nefes almak, tüm bu
          hengameyi anlamlandırmak için bize fırsat sunar.
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: '1.5rem' }}>
          Stresle başa çıkmayı öğrenmezsek, zamanla bu koşuşturma içinde kendimizi kaybedebiliriz. Sürekli bir yerden bir yere koştururken aslında neyin peşinde olduğumuzu unutur
          hale geliriz. Kendimize sormamız gereken soru şudur: Bu hızda yaşarken gerçekten ne kazanıyoruz? Daha önemlisi, ne kaybediyoruz?
        </Typography>
        <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '1rem' }}>
          Stres Yönetimi Neden Önemlidir?
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: '1.5rem' }}>
          Stres dediğin, başta "olur öyle şeyler" diyerek geçiştirmeye çalıştığımız ama farkına varmadan içimizi kemiren bir his. İlk başta o kadar da önemli değilmiş gibi görünür,
          ama zamanla etkisi bir kar topu gibi büyür. Uzun süreli stresin hayatımıza etkileri düşündüğümüzden çok daha derin olabilir. Mesela gün boyu süren o yorgunluk hissi,
          geceleri bir türlü uyuyamamak, sürekli bir endişe hali... Bu belirtiler, stresin bize oynadığı küçük oyunlar gibi görünse de, aslında bedenimize ve zihnimize derin izler
          bırakır. Sürekli tetikte olmak bedenimizi yorar; bağışıklık sistemimizi zayıflatır, bizi hastalıklara karşı savunmasız hale getirir. Duygusal olarak ise kaygı, depresyon
          ve tükenmişlik gibi duygularla baş başa kalırız.
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: '1.5rem' }}>
          Stresin bu kadar sinsi olmasının sebebi, yavaş yavaş birikmesi ve aniden patlak vermesidir. Başlangıçta fark edemeyebiliriz; biraz baş ağrısı, biraz yorgunluk derken, bir
          de bakmışız ki vücudumuz ve zihnimiz isyan ediyor. Bu noktaya gelmemek için stres yönetimini bir alışkanlık haline getirmeliyiz. Günlük hayatımızda stresle başa çıkmanın
          yollarını öğrenmezsek, bir gün bu yük altında eziliriz. Ve unutmayın, stres sadece sizi değil çevrenizdeki insanları da etkiler. Aileniz, arkadaşlarınız, iş
          arkadaşlarınız... Stresle başa çıkamadığınızda, bu etki çemberi büyür ve hayatınızdaki ilişkiler de bundan zarar görür.
        </Typography>

        <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '1rem' }}>
          Stresi Tetikleyen Faktörler
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: '1.5rem' }}>
          Hayatımızın her köşesinde stres yaratabilecek potansiyel kaynaklar saklıdır. İş yerindeki bitmek bilmeyen toplantılar, son teslim tarihleri, her gün yan yana çalıştığınız
          ama bir türlü anlaşamadığınız o meslektaş... Sonra ilişkiler; sevdiklerimizle yaşadığımız anlaşmazlıklar, birbirimizi anlamadığımız anlar, hayal kırıklıkları. Aileden
          gelen beklentiler, arkadaşlardan gelen yanlış anlaşılmalar... Ve tabii ki para... Ay sonunu nasıl getireceğiz diye düşünüp durmak, marketteki fiyat etiketlerine bakarken
          iç geçirmeler...
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: '1.5rem' }}>
          Bunların dışında, kendi iç dünyamızda yarattığımız stres faktörleri de var. Mükemmeliyetçilik, kendimizi sürekli başkalarıyla kıyaslamak, "yeterince iyi" olmama
          korkusu... Tüm bunlar, bizim kendi kendimize yüklediğimiz stres kaynaklarıdır. Aslında dışarıdaki stres faktörlerinden daha zararlıdırlar çünkü içten içe bizi yer
          bitirirler. Bu tetikleyiciler bir araya geldiğinde, stresin üstesinden gelmek bazen imkânsız gibi görünebilir. Ama bu tetikleyicilerin farkında olmak ve onları yönetmeyi
          öğrenmek, stresle baş etmenin ilk adımıdır. Çünkü bir kez neyin sizi strese soktuğunu anlarsanız, onunla başa çıkmak için stratejiler geliştirmeye başlayabilirsiniz.
        </Typography>

        <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '1rem' }}>
          Stres Yönetimi Teknikleri
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: '1.5rem' }}>
          Peki, bu stresle nasıl başa çıkacağız? Elimizde hangi araçlar var? Gelin, stres yönetiminde kullanabileceğiniz bazı tekniklere bir göz atalım. Mesela meditasyon,
          bedenimizi ve zihnimizi aynı hizaya getirmenin en etkili yollarından biridir. Sadece birkaç dakikanızı ayırarak zihninizi sakinleştirir, bedeninizi rahatlatır. Derin
          nefesler alıp vererek zihninizdeki karmaşayı bir kenara bırakabilir, sadece "an"da kalabilirsiniz. Spor yapmak ise bir diğer güçlü araçtır. Yürüyüşe çıkmak, yoga yapmak,
          hatta sadece birkaç esneme hareketi bile bedenimizi rahatlatır. Spor endorfin adı verilen "mutluluk hormonu"nun salgılanmasını sağlar, bu da hem fiziksel hem de zihinsel
          olarak daha iyi hissetmenize yardımcı olur. Sağlıklı beslenmek de bu denklemin önemli bir parçasıdır; fast food yerine daha doğal ve taze yiyecekler tüketmek, bedenimizin
          stresle daha iyi başa çıkmasını sağlar.
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: '1.5rem' }}>
          Sosyal destek almak da altın değerindedir. Arkadaşlarla dertleşmek, yalnız olmadığınızı hissetmek size güç verir. Hobi edinmek ise zihnimizi boşaltmanın en güzel
          yollarından biridir; resim yapmak, yazı yazmak, örgü örmek... Kendimize bu anları hediye etmek, stresin hayatımızı ele geçirmesini önler. Ayrıca doğayla iç içe olmak da
          stresi azaltmanın en etkili yollarından biridir. Doğada geçirilen kısa bir zaman bile ruh halimizi iyileştirir, zihin açıcı bir etki yaratır. Kendimize zaman ayırmak
          aslında kendimize değer vermek demektir ve bu, stresle baş etmenin en etkili yollarından biridir.
        </Typography>

        <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '1rem' }}>
          Günlük Hayatta Stresi Azaltma İpuçları
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: '1.5rem' }}>
          Günlük hayatımızda stresle baş etmek için bazı basit ama etkili adımlar atabiliriz. Sabahları güne hafif bir müzikle başlamak, zihnimizi sakinleştirir ve güne enerjik bir
          başlangıç yapmamızı sağlar. Kahvaltıda sevdiğiniz bir şeyler yemek, size enerji verir ve gün boyu sürecek bir motivasyon sağlar. Mesela iş yerinde kendinizi stresli
          hissettiğinizde bir bardak su için, derin bir nefes alın ve kısa bir yürüyüş yapın. Bu küçük molalar zihninizi tazeler ve işlerinize daha odaklanmış bir şekilde geri
          dönmenizi sağlar.
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: '1.5rem' }}>
          Akşamları ise tüm elektronik cihazları bir kenara bırakıp, kendinize bir kahve yapın ve sevdiğiniz bir kitabı okuyun. Elektronik cihazlardan uzak kalmak zihninizin
          dinlenmesine ve rahatlamasına yardımcı olur. Kendinize küçük ödüller vermek de stresli anları daha kolay atlatmanızı sağlar. Haftanın sonunda sevdiğiniz bir film izlemek
          ya da kendinize küçük bir hediye almak motive edici olabilir. Unutmayın, küçük değişiklikler büyük farklar yaratır ve stresin etkilerini hafifletmek için bu tür basit
          alışkanlıklar edinmek önemlidir.
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: '1.5rem' }}>
          Stresin sizi ele geçirmesine izin vermeyin. Onunla mücadele etmek, başa çıkmak zor olabilir ama imkansız değil. Doğru stratejiler, biraz kendinize ayırdığınız zaman ve
          sevgi dolu bir yaklaşımla stresin hayatınızdaki etkisini minimize edebilir, daha huzurlu ve dengeli bir yaşam sürebilirsiniz. Unutmayın, bu sadece sizin için değil,
          sevdikleriniz ve tüm çevreniz için de önemli. Stresle mücadele etmek, hayatın tüm zorluklarına karşı daha güçlü ve dayanıklı olmanızı sağlayacak.
        </Typography>
      </>
    ),
    author: 'Yazar Adı',
    date: '2024-08-28'
  },
  {
    id: 2,
    title: 'Her lokma ardındaki derin çatışma: Yeme Bozuklukları',
    subject: 'yeme-bozukluğu',
    image: '/images/yemebozuklugu.png',
    content: (
      <>
        <Typography variant="body1" sx={{ marginTop: '3.5rem', marginBottom: '1.5rem' }}>
          Bazı günler, sadece yemek yemek bile bir sınav gibi gelir. Sofranın başına oturduğunda, önündeki tabağa baktığında, hissetmen gereken o huzur yerini karmaşık bir duyguya
          bırakır. Her lokma zihninde bir yankı, bedeninde bir ağırlık yaratır. Yemek sadece bir ihtiyaç olmaktan çıkar; duygularınla, düşüncelerinle ve hatta kendinle girdiğin bir
          mücadeleye dönüşür. Peki, bu içsel çatışmanın ardında ne var? Gel, bu karmaşık dünyayı birlikte keşfedelim.
        </Typography>
        <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '1rem' }}>
          Yeme Bozuklukları Nelerdir?
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: '1.5rem' }}>
          Yeme bozuklukları dediğimizde, akla hemen anoreksiya, bulimiya gibi tanıdık terimler gelir. Ancak bu bozukluklar sadece buzdağının görünen kısmıdır. Anoreksiya, kişinin
          kilo alma korkusuyla neredeyse hiçbir şey yememesi, bedenini açlığa mahkum etmesidir. Bu durum sadece fiziksel açlık değil, aynı zamanda zihinsel bir yıkımı da
          beraberinde getirir. Bulimiya ise tam tersi, aşırı yemenin ardından suçluluk duyarak kendini temizlemeye çalışmaktır. Bir süre sonra, yemek yemek ve ardından temizlenmek
          bir döngüye dönüşür, her seferinde biraz daha yıpranırsın.
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: '1.5rem' }}>
          Bir de tıkınırcasına yeme bozukluğu var. Kişi bir oturuşta çok fazla yemek yer, ama sonrasında kusma ya da aşırı egzersizle bunu telafi etme çabası yoktur. Bu da bir
          döngü halini alır; kontrol edilemeyen açlık krizleri ve ardından gelen suçluluk hissi vardır.
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: '1.5rem' }}>
          Ve daha az bilinen ama bir o kadar yıkıcı olan orthoreksiya var. Sağlıklı beslenme takıntısı… Her lokmanın içinde bir tehdit arayan, sağlıklı olmanın sınırlarını zorlayan
          bir takıntı. Tabağındaki her malzemenin besin değerini bilmek; onların nasıl üretildiğini, nereden geldiğini de sorgulamak. Yemek yerken bir rahatlama, bir keyifte değil;
          aksine sürekli bir tedirginlik içinde olmak.
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: '1.5rem' }}>
          Bu bozukluklar, yemekle olan ilişkimizi tamamen alt üst edebilir ve bu durum kişinin sosyal yaşamını da derinden etkiler.
        </Typography>

        <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '1rem' }}>
          Yeme Bozukluklarının Nedenleri
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: '1.5rem' }}>
          Peki, neden bazı insanlar bu kısır döngüye düşer? Aslında bu sorunun kesin bir cevabı yok. Genetik faktörler, travmalar, düşük benlik saygısı, toplumun dayattığı güzellik
          standartları... Hepsi birer neden olabilir. Ancak asıl mesele, yeme bozukluklarının çoğunlukla bir semptom olduğu. Yani bu bozukluklar, zihinsel ve duygusal bir yarayı
          kapatmaya çalışmanın bir yolu olabilir. Belki çocukluğunda sürekli eleştirildin, belki de toplumsal normlara uyma baskısı altında ezildin. Ya da basitçe… Kendini ifade
          edemediğin, kontrol edemediğin bir dünyada, en azından yeme alışkanlıklarını kontrol etmek istedin.
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: '1.5rem' }}>
          Toplumun dayattığı güzellik standartları da bu işin en büyük tetikleyicilerinden biri. İdeal vücut ölçüleri, dergilerdeki mükemmel fotoğraflar, sosyal medyada her gün
          karşılaştığımız kusursuz hayatlar... Bu görüntüler aslında kimsenin gerçek hayatta ulaşamayacağı standartları normalleştirir. Biz de bu standarda ulaşma umuduyla, kendi
          bedenimize savaş açarız. “Daha ince olmalıyım”, “Daha kaslı olmalıyım”, “Bunu yememeliyim” gibi düşünceler, zihnimizin bir parçası haline gelir. Bedenimizle olan bu savaş
          zamanla hayatımızın merkezine oturur.
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: '1.5rem' }}>
          Ancak yeme bozukluklarının ardında yatan nedenler sadece dış faktörlerle sınırlı değil. Bazen içsel bir boşluk, duygusal bir eksiklik de bu bozuklukları tetikleyebilir.
          Bir şeylerden kaçmak, bir şeyleri bastırmak için yemeği kullanmak… Yeme bozuklukları aslında zihnin bir çığlığıdır; “Benimle ilgilen, bana bak” diyen bir yardım
          çağrısıdır. Bu çağrıya kulak vermek, onun ardındaki gerçek nedenleri keşfetmek, iyileşmenin ilk adımıdır.
        </Typography>

        <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '1rem' }}>
          Yeme Bozukluklarının Fiziksel ve Zihinsel Etkileri
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: '1.5rem' }}>
          Yeme bozuklukları sadece zihinsel olarak değil, fiziksel olarak da vücutta derin yaralar bırakır. Vücut sürekli açlıkla mücadele ederken zamanla metabolizma yavaşlar,
          bağışıklık sistemi zayıflar, kemikler güçsüzleşir. Anoreksiya gibi bozukluklar kalp ritmini bile bozabilir, hatta kalıcı organ hasarlarına yol açabilir. Bulimiya ise diş
          minesinin aşınmasına, sindirim sistemi problemlerine neden olur. Sürekli aşırı yemek yemek ya da hiç yememek, bedenin doğal dengesini bozar. Bu dengesizlik kişinin genel
          sağlığını tehdit eder ve uzun vadede ciddi sağlık sorunlarına yol açabilir.
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: '1.5rem' }}>
          Ama asıl yara zihinde açılır. Yeme bozuklukları sürekli bir yetersizlik ve suçluluk hissiyle birlikte gelir. Her lokma bir savaş, her öğün bir hesaplaşma olur. Zihnin
          kendine düşman olur, her aynaya baktığında gördüğün beden kendinle ilgili tüm yargılarını şekillendirir. Bir lokma fazla yemek tüm gün boyunca aklını kurcalayabilir.
          Beden algısı bozulur, kendini her zaman daha kilolu, daha çirkin, daha yetersiz hissedersin. Bu kısır döngü kişinin sosyal hayatını, ilişkilerini, hatta iş performansını
          bile etkileyebilir. Yeme bozukluklarının bu kadar derin ve yıkıcı olması onları daha da tehlikeli hale getirir.
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: '1.5rem' }}>
          Ve en kötüsü bu bozukluklar insanın tüm benliğini ele geçirebilir. Sadece fiziksel bir görünüm meselesi olmaktan çıkar. İnsanın kimliğiyle, kendine olan inancıyla, hayata
          bakışıyla ilgili derin bir problem haline gelir. Kişinin kendine olan saygısını, özgüvenini, hatta yaşama arzusunu bile yıkabilir. Bu bozukluklar sadece bireyin kendisine
          zarar vermekle kalmaz, aynı zamanda çevresindeki insanları da etkiler. Sevdiklerinin seni endişeyle izlediğini görmek, bu döngüyü daha da acı verici hale getirir.
        </Typography>

        <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '1rem' }}>
          Yeme Bozuklukları ile Başa Çıkma Yöntemleri
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: '1.5rem' }}>
          Yeme bozukluklarıyla başa çıkmak uzun ve zorlu bir süreçtir. İlk adım, bu durumun bir problem olduğunu kabul etmektir. “Benim bir sorunum var” diyebilmek, bu karanlık
          döngüden çıkmanın ilk adımıdır. Sonrasında ise profesyonel bir destek almak çok önemlidir. Terapi, bu sürecin en kritik parçasıdır. Özellikle bilişsel davranışçı terapi
          (BDT), yeme bozukluklarının ardındaki düşünce kalıplarını ve inançları değiştirmede etkili olabilir. Bu terapi yöntemi, kişinin kendine ve bedenine olan bakış açısını
          yeniden şekillendirmesine yardımcı olabilir.
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: '1.5rem' }}>
          Aynı zamanda bu süreçte sevdiklerinin desteği de çok değerlidir. Bu bozukluklar genellikle yalnızlıktan beslenir. Bu yüzden etrafındaki insanlarla duygularını paylaşmak,
          bu yükü hafifletebilir. Bir destek grubu bulmak, benzer deneyimler yaşayan insanlarla bir araya gelmek de faydalı olabilir. Bu sayede yaşadığın şeyde tek olmadığını,
          başkalarının da benzer mücadeleler verdiğini görebilirsin. Bu tür bir destek, iyileşme sürecinde önemli bir motivasyon kaynağı olabilir.
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: '1.5rem' }}>
          Fiziksel aktiviteler de sağlıklı bir beden algısı geliştirmene yardımcı olabilir. Yoga, yürüyüş gibi egzersizler hem bedeninle barışmanı sağlar, hem de zihinsel olarak
          rahatlamana yardımcı olur. Kendine iyi bakmak, bedenine ve zihnine saygı duymak bu sürecin en önemli parçalarından biridir. Yeme alışkanlıklarını yavaş yavaş yeniden
          yapılandırmak, sağlıklı beslenme alışkanlıkları geliştirmek de uzun vadede çok faydalı olacaktır. Küçük adımlarla başlamak, bu süreci daha yönetilebilir hale getirir.
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: '1.5rem' }}>
          Sonuç olarak yeme bozuklukları, duygusal ve zihinsel yaraların bir yansıması olabilir. Bu yolculuk zor olsa da, her adım iyileşmeye doğru atılmış bir adımdır. Kendinle
          barışmanın ve bedenine yeniden sevgiyle bakmanın zamanı geldiğinde, yalnız olmadığını bilmek önemlidir. Her yeni gün kendine ve bedenine daha iyi davranabileceğin bir
          fırsat sunar; ve her lokma, senin için yeni bir başlangıç olabilir !
        </Typography>
      </>
    ),
    author: 'Yazar Adı',
    date: '2024-08-28'
  },
  {
    id: 3,
    title: 'Zihnin sessiz karanlığı: Anksiyeteyi Anlamak',
    subject: 'anksiyete',
    image: '/images/anksiyete.png',
    content: (
      <>
        <Typography variant="body1" sx={{ marginTop: '3.5rem', marginBottom: '1.5rem' }}>
          Ah, anksiyete… Hani şu sabah kalktığında hiç sebepsiz yere içine çöken his var ya, işte o. Bazen gününü bile rezil edebilecek kadar güçlü, bazen de sadece bir fısıltı
          gibi. Ama ne olursa olsun varlığını hep hissettiriyor.
        </Typography>
        <Typography variant="body1" sx={{ marginTop: '3.5rem', marginBottom: '1.5rem' }}>
          Anksiyete dediğimizde, aslında hepimizin bir şekilde tanıştığı o tatsız misafirden bahsediyoruz. Ama hadi gel, onu daha yakından tanıyalım.
        </Typography>
        <Typography variant="body1" sx={{ marginTop: '3.5rem', marginBottom: '1.5rem' }}>
          Birçok kişi anksiyeteyi stresle karıştırır. Stres, belirli bir olay veya durum karşısında ortaya çıkan kısa süreli bir tepkiyken, anksiyete daha derinlere inen,
          genellikle bir sebep olmadan bile kendini gösterebilen bir durumdur. Örneğin bir sunum yapmadan önce hissettiğin o gerginlik strestir, ama aynı gerginliği sebepsiz yere
          her sabah yaşamak anksiyeteye işaret edebilir. Anksiyete hayatını yönetmeye başladığında, işte o zaman onunla başa çıkma zamanı gelmiş demektir.
        </Typography>
        <Typography variant="body1" sx={{ marginTop: '3.5rem', marginBottom: '1.5rem' }}>
          Anksiyete bazen hiç beklemediğin bir zamanda, en mutlu olduğunu düşündüğün anlarda bile kapını çalabilir. Düşünsene en sevdiğin arkadaşlarınla bir araya gelmişsin,
          kahkahalar havada uçuşuyor. Ama bir anda sanki biri ışıkları kapatmış gibi, içindeki huzur yerini endişeye bırakıyor. “Ya bir şey olursa?”, “Ya bu mutluluk uzun
          sürmezse?” gibi düşünceler beyninde dolanmaya başlıyor. O anı yaşamak yerine, geleceğin belirsizliğinde kaybolup gidiyorsun anksiyetenin karanlık kollarında.
        </Typography>
        <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '1rem' }}>
          Peki nedir bu anksiyete ?
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: '1.5rem' }}>
          Anksiyete, aslında bir alarm sistemi. Beynin tehlike olarak algıladığı bir duruma karşı verdiği tepki. Ama işin ironik tarafı, çoğu zaman ortada bir tehlike falan yok.
          Yani sen sadece işe geç kalmışsın ya da markette istediğin dondurmayı bulamamışsın… Beynin ise bunları bir kıyamet habercisi gibi algılıyor. Sonuç? Kalbin deli gibi
          çarpıyor, ellerin titriyor, nefes almak zorlaşıyor ve bir panik dalgası tüm vücudunu sarıyor. Bir yandan, “Abartma, ne olacak ki?” demek istiyorsun, ama diğer yandan
          bedenin sanki dünyanın sonu gelmiş gibi tepki veriyor. İşte bu anksiyetenin sana oynadığı oyun.
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: '1.5rem' }}>
          Bazı günler bu oyunun kurallarını öğrenmek istesen de, anksiyete adil başlamaz. Sabah gözlerini açar açmaz göğsünde bir ağırlık, sanki dünya omuzlarına binmiş gibi
          hissedersin. Hiçbir şeyin anlamı yokmuş gibi gelir; ne işin, ne arkadaşların, ne de hayallerin… Her şey bir anda anlamsızlaşır. Anksiyete, yaşamın anlamını sorgulatan ve
          seni en derin düşüncelere sürükleyen bir güce sahip. Bu yüzden de birçok kişi bu karanlık döngüden çıkış yolu bulmakta zorlanır.
        </Typography>

        <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '1rem' }}>
          İyi de, neden ‘ben’ yaşıyorum bunu ?
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: '1.5rem' }}>
          Katılıyorum, isyan edilesi. Neden bazı insanlar daha fazla anksiyete yaşar? Burası biraz karışık. Genetik faktörler, çocukluk travmaları, stresli bir yaşam tarzı… Liste
          uzun. Ama bir de sosyal medya var. Hani şu hepimizin "hayatları ne kadar mükemmel" diye düşündüğümüz, sürekli mutlu yüzlerin olduğu yer. İşte o, anksiyeteyi körükleyen en
          büyük sebeplerden biri. Herkesin hayatı harika gidiyormuş gibi gözükürken sen bir köşede kendi hayatını sorguluyorsun. Bu kıyaslama, beynine “yetersizsin” mesajını
          sürekli gönderiyor. Böylece anksiyete büyüyüp serpiliyor.
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: '1.5rem' }}>
          Bir de şu var, bazı insanlar genetik olarak daha hassaslar. Ailesinde anksiyete veya depresyon geçmişi olan biri, bu duygulara daha yatkın olabilir. Bir arkadaşım vardı,
          anksiyete yaşadığında ailesinde de benzer durumlar olduğunu fark etmişti. O zaman anksiyetenin sadece bir “zayıflık” olmadığını, biyolojik kökenleri de olabileceğini
          anlamıştı.
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: '1.5rem' }}>
          Anksiyetenin bir diğer nedeni ise çocukluk travmaları olabilir. Örneğin sürekli eleştirilmiş, ya da duygusal olarak ihmal edilmiş biriysen, yetişkinlikte kendini sürekli
          yetersiz hissetmen şaşırtıcı değil.
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: '1.5rem' }}>
          Modern yaşam tarzı da anksiyeteyi tetikleyen önemli bir faktör. Sürekli olarak bir yerlere yetişmeye çalışmak, yapılacak işler listesi hiç bitmiyormuş gibi hissetmek… Tüm
          bunlar beynin sürekli olarak "savaş ya da kaç" modunda kalmasına neden olabilir. Anksiyete bu modun kronikleşmiş hali gibidir; her an bir tehlike varmış gibi tetikte
          bekleyen bir zihin durumudur.
        </Typography>

        <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '1rem' }}>
          Anksiyete Belirtileri
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: '1.5rem' }}>
          Eğer bir gün, kalbin bir maraton koşuyormuş gibi atmaya başlarsa ya da ansızın nefes almakta zorlanırsan, sebebi anksiyete olabilir. Belki de sürekli bir tedirginlik hali
          yaşıyorsundur ya da en kötü senaryoları kafanda döndürüp duruyorsundur. İşte bunlar küçük işaretleri. Anksiyete, herkesin kapısını farklı şekillerde çalabilir. Kimisinde
          mide ağrılarıyla, kimisinde ise sürekli bir huzursuzluk hissiyle ortaya çıkar. Ama genellikle bedenin verdiği sinyalleri dinlemek önemlidir. Çünkü bedenin, aklından önce
          anksiyetenin geldiğini fark eder.
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: '1.5rem' }}>
          Bazen anksiyete fiziksel belirtilerle de kendini gösterir. Mide bulantısı, baş dönmesi, kas gerginliği… Bir anda hiçbir neden yokken başını döndürmeye başlar, midende
          kelebekler uçuşur ama bu kelebekler pek de hoş hissettirmez. Hatta bazen bu belirtiler yüzünden hasta olduğunu bile düşünebilirsin. Doktora gidip tüm testleri
          yaptırırsın, ama sonuçlar normal çıkar. İşte o zaman anksiyetenin sadece zihinsel değil, aynı zamanda fiziksel bir savaş olduğunu anlarsın.
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: '1.5rem' }}>
          Belirtiler sadece fiziksel değildir elbette; zihinsel belirtiler de oldukça yoğundur. Sürekli bir endişe hali, geleceğe dair karamsar düşünceler, uykusuzluk… Anksiyete,
          zihin ve bedenin bir araya gelip seni ele geçirdiği bir savaş gibidir. Bu belirtiler bazen öyle kuvvetli olur ki, günlük hayatını bile etkileyebilir. İşine odaklanmakta
          zorlanır, sevdiklerinle kaliteli zaman geçiremezsin. Anksiyete, hem fiziksel hem de zihinsel olarak seni tüketebilir.
        </Typography>
        <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '1rem' }}>
          Anksiyete ile Baş Etme Yolları
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: '1.5rem' }}>
          Peki, bu davetsiz misafirle nasıl başa çıkabiliriz? Her şeyden önce, onu kabullenmek gerek. “Böyle hissetmemem gerekir” diyerek inkâr etmeye çalışmak, sadece durumu daha
          da zorlaştırır. Küçük adımlarla ilerlemek lazım. Nefes egzersizleri, meditasyonlar, fiziksel aktiviteler… Bunlar anksiyetenin seni esir almasını engelleyecek en iyi
          dostlar. Ayrıca çevrenden destek almak, hislerini paylaşmak da büyük bir fark yaratır. Unutma, yalnız değilsin. Anksiyete birçok insanın yüzleştiği bir durum ve onunla
          başa çıkmanın yolları var.
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: '1.5rem' }}>
          Başlangıçta küçük adımlarla ilerlemek önemlidir. Her sabah uyandığında derin nefesler alarak güne başlamayı dene. İlk başta bu basit bir şey gibi görünebilir ama nefes
          egzersizleri anksiyetenin kontrolünü ele geçirmende büyük bir rol oynar. Bir diğer etkili yöntem ise, anksiyetenin seni ele geçirdiğini hissettiğin anlarda, o anı
          kabullenmektir. Onu kovmaya çalışmak yerine, “Tamam, şu anda anksiyete hissediyorum, ama geçecek” demek bile zihnini sakinleştirebilir. Spor, yürüyüş veya yoga gibi
          aktiviteler de bedeni ve zihni rahatlatır.
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: '1.5rem' }}>
          Tabii, kök nedenlerini bulmak da önemli. Anksiyete genellikle yüzeyde görünen bir semptomdur; derinlerde yatan başka duygusal yaralar veya çözülmemiş problemler olabilir.
          Bu yüzden bir terapistten destek almak, anksiyetenin kökenine inmek için önemli bir adımdır. Terapiler anksiyeteyi tetikleyen unsurları anlamana ve bunlarla başa çıkma
          stratejileri geliştirmen için sana rehberlik edebilir. Bunun yanında günlük rutinine küçük ama etkili değişiklikler eklemek de faydalı olabilir. Örneğin her gün kısa bir
          yürüyüş yapmak, sağlıklı beslenmeye özen göstermek, hatta sevdiğin bir hobiye zaman ayırmak çok faydalı olabilir.
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: '1.5rem' }}>
          Anksiyete ile başa çıkmanın bir diğer yolu da kendine zaman ayırmak ve bu zaman diliminde sadece keyif aldığın şeylerle ilgilenmektir. Bir fincan kahve, sevdiğin bir
          kitap ya da sadece sessiz bir an bile, zihnini rahatlatıp anksiyeteden uzaklaşmana yardımcı olabilir. Unutma, kendine iyi bakmak anksiyeteyle başa çıkmada en önemli
          adımlardan biridir.
        </Typography>
      </>
    ),
    author: 'Yazar Adı',
    date: '2024-08-28'
  },
  {
    id: 4,
    title: 'Yorgun Gece, Dolu Tabak: Tıkınırcasına Yeme ile Mücadele Rehberi',
    subject: 'binge-eating',
    image: '/images/bingeating.png',
    content: (
      <>
        <Typography variant="body1" sx={{ marginTop: '3.5rem', marginBottom: '1.5rem' }}>
          Bol stresli bir iş günü, akşam eve geldiğinizde ise gözlerinizin altında yorgunluk çizgileri var. Yatakta dönüp duruyorsunuz, bir türlü dalamıyorsunuz. Gözünüz uyku
          yerine mutfağa kayıyor. Evin içi sessiz, sadece buzdolabının derinliklerinden gelen sesler var. Dolabı açıyorsunuz, anne usülü yapılmış bisküvili pasta size göz kırpıyor.
          Tabak çıkartmadan ayaküstü iki dilim atıştırıyorsunuz. Sonra masanın üzerindeki açılmamış cips paketi gözünüze ilişiyor, 5 dk sonra tamamen bitmiş olduğunu fark
          ediyorsunuz.
        </Typography>
        <Typography variant="body1" sx={{ marginTop: '3.5rem', marginBottom: '1.5rem' }}>
          "Acaba çekmecedeki gofret hala yerinde mi? Tüh. Aslında aç da değilim. Neden doymuyorum ki?"
        </Typography>
        <Typography variant="body1" sx={{ marginTop: '3.5rem', marginBottom: '1.5rem' }}>
          Biliyorum, bazen kendimizi mutfakta bulmak, yaşadığımız stres ve sıkıntıları geçici bir süreliğine unutmanın bir yolu gibi görünebilir. Ama merak etmeyin, yalnız
          değilsiniz! Gelin, bu konuyu derinlemesine keşfedelim ve sağlıklı yeme alışkanlıkları oluşturma yolunda birlikte adım atalım!
        </Typography>

        <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '1rem' }}>
          Binge Eating (Tıkınırcasına Yeme) Nedir?
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: '1.5rem' }}>
          Tıkınırcasına yeme, aslında bir çeşit yeme bozukluğudur. “Tıkınmak” derken sadece açlıktan değil, duygusal ya da psikolojik sebeplerle de fazladan yeme davranışını kast
          ediyoruz. Kısacası, normalin çok üzerinde miktarda yemek yediğinizde bu davranış sadece “açım” demekten ibaret değil. Bazen can sıkıntısı, stres ya da kendimizi kötü
          hissettiğimizde, bu durumu bir tür kaçış yolu olarak kullanıyoruz. Yemek yiyerek kendimizi geçici olarak iyi hissetmeye çalışıyoruz. Ama bu geçici rahatlama hali, çoğu
          zaman sonrası daha derin bir pişmanlık ve suçluluk duygusuyla sonuçlanıyor.
        </Typography>

        <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '1rem' }}>
          Binge Eating’in Nedenleri ve Sonuçları
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: '1.5rem' }}>
          Peki neden tıkınırcasına yeriz? Aslında bu konuda birkaç farklı neden var. Gelin, bunları daha yakından inceleyelim:
        </Typography>
        <Box component="ul" sx={{ paddingLeft: '1.5rem', marginBottom: '1.5rem' }}>
          <Typography component="li">
            <strong>Duygusal Yeme:</strong> Stres, üzüntü ya da yalnızlık gibi duygusal durumlar bizi yemek yemeye itebilir. Bu, aslında duygusal bir rahatlama arayışıdır. Bir tür
            “yemekle kendimizi ödüllendirme” hali de diyebiliriz.
          </Typography>
          <Typography component="li">
            <strong>Genetik Faktörler:</strong> Ailede benzer sorunlar yaşanmışsa, genetik yatkınlık da olabilir. Bu davranış genlerimizle birlikte bize miras kalmış olabilir.
          </Typography>
          <Typography component="li">
            <strong>Kendine Güven:</strong> Bazı günler kendimizi yetersiz hissedebiliriz, yeme alışkanlıklarımız da bu duyguları bastırmak için bir yol olabilir.
          </Typography>
          <Typography component="li">
            <strong>Çevresel ve Sosyal Faktörler:</strong> Sosyal medya veya çevremiz tıkınırcasına yeme davranışını tetikleyebilir.
          </Typography>
        </Box>
        <Typography variant="body1" sx={{ marginBottom: '1.5rem' }}>
          Tıkınırcasına yemenin sonuçları ciddi olabilir. Kilo alımı, obezite, diyabet gibi fiziksel sağlık sorunları yaşanabilir. Bunun yanında, bu davranışın psikolojik etkileri
          de var: depresyon ve anksiyete gibi durumlarla başa çıkmak zorlaşabilir.
        </Typography>

        <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '1rem' }}>
          Binge Eating ile Mücadele Yöntemleri
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: '1.5rem' }}>
          Şimdi gelelim bu durumu nasıl aşabileceğimize. İşte bazı pratik öneriler:
        </Typography>
        <Box component="ul" sx={{ paddingLeft: '1.5rem', marginBottom: '1.5rem' }}>
          <Typography component="li">
            <strong>Farkındalık Oluşturun:</strong> Öncelikle, tıkınırcasına yeme davranışının farkına varmak çok önemli. Kendi yeme alışkanlıklarınızı gözden geçirin ve ne zaman,
            neden bu şekilde davrandığınızı anlamaya çalışın.
          </Typography>
          <Typography component="li">
            <strong>Duygusal Yeme ile Başa Çıkın:</strong> Eğer yeme alışkanlıklarınız duygusal nedenlerden kaynaklanıyorsa, bu duygularla başa çıkmak için çeşitli yöntemler
            deneyebilirsiniz. Meditasyon, derin nefes alma teknikleri ya da terapi bu konuda yardımcı olabilir.
          </Typography>
          <Typography component="li">
            <strong>Küçük Adımlar Atın:</strong> Büyük değişimlerin, küçük adımlarla başladığını unutmayın. Sağlıklı alışkanlıklar geliştirmek için küçük ama istikrarlı adımlar
            atın. Örneğin yemeklerinizi küçük tabaklarda servis etmek ya da belirli bir düzen içinde tüketmek gibi.
          </Typography>
          <Typography component="li">
            <strong>Destek Alın:</strong> Bir profesyonel, size uygun stratejiler geliştirebilir ve bu süreçte rehberlik edebilir. Psikologlar ve diyetisyenlerden yardım
            alabilirsiniz.
          </Typography>
        </Box>

        <Typography variant="body1" sx={{ marginBottom: '1.5rem' }}>
          Bu durumla başa çıkmak için farkındalık oluşturmak, duygusal yemeyi kontrol etmek ve profesyonel destek almak önemlidir.
        </Typography>

        <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '1rem' }}>
          Sağlıklı Yeme Alışkanlıkları Oluşturma
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: '1.5rem' }}>
          Sağlıklı yeme alışkanlıkları oluşturmak, tıkınırcasına yemeyi azaltmanın en etkili yollarından biridir. İşte bazı pratik öneriler:
        </Typography>
        <Box component="ul" sx={{ paddingLeft: '1.5rem', marginBottom: '1.5rem' }}>
          <Typography component="li">
            <strong>Dengeli Beslenme:</strong> Günlük beslenmenizde protein, lif ve sağlıklı yağları yeterli miktarda aldığınızdan emin olun. Meyve, sebze ve tam tahıllı ürünlere
            öncelik verin.
          </Typography>
          <Typography component="li">
            <strong>Öğün Atlamayın:</strong> Düzenli ve dengeli öğünler tüketmek, açlık krizlerini ve aşırı yemeyi önleyebilir.
          </Typography>
          <Typography component="li">
            <strong>Yavaş Yiyin:</strong> Yemeğinizi yavaş yediğinizde, tokluk hissini daha iyi algılayabilirsiniz. Bu da aşırı yemenin önüne geçebilir.
          </Typography>
          <Typography component="li">
            <strong>Su İçin:</strong> Bol su içmek, hem açlık hissini kontrol altında tutmanıza hem de genel sağlığınıza katkıda bulunur.
          </Typography>
        </Box>
        <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '1rem' }}>
          Unutmayın...
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: '1.5rem' }}>
          Stresli günlerin sonunda mutfağa gitmek ve o anda bize iyi gelen yiyeceklere yönelmek hepimizin başına gelebilir.Ancak kendimizi daha iyi hissetmek için yemek dışındaki
          yolları keşfetmek de mümkün. O gün dolabın karşısında geçirdiğiniz birkaç dakikayı hatırlayın; aslında tok olduğunuz halde o cips paketini bitirdiğiniz anı. İşte tam da o
          an, farkındalıkla durup kendinize şu soruyu sorabilirsiniz: “Gerçekten neye ihtiyacım var?”
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: '1.5rem' }}>
          Tıkınırcasına yemenin üstesinden gelmek kolay değil, ama küçük adımlarla başlayarak yemekle olan ilişkinizi yeniden tanımlayabilirsiniz. Stresin ve yorgunluğun sizi
          mutfakta değil, daha sağlıklı yollarla rahatlamaya yönlendirdiği bir hayat yaratabilirsiniz. Sağlıklı alışkanlıklar geliştirmek hem bedeninizi hem de zihninizi besler.
          Şimdi, her seferinde biraz daha bilinçli seçimler yaparak kendinize ve sağlığınıza bir iyilik yapma zamanı!
        </Typography>
      </>
    ),
    author: 'Yazar Adı',
    date: '2024-09-01'
  },
  {
    id: 5,
    title: '“Overthinking” masasında nasıl “çatal bıçak” kullanırsınız?',
    subject: 'overthinking',
    image: '/images/overthinking.png',
    content: (
      <>
        <Typography variant="body1" sx={{ marginTop: '3.5rem', marginBottom: '1.5rem' }}>
          Bazen kafamızın içi bir açık büfe gibi olur. Her türlü düşünce ve endişe bir araya gelir, birbirine karışır ve düzeni bulmak zorlaşır. Ne seçmeye çalıştığımızı ve hangi
          düşünceyi öncelikli olarak ele alacağımızı belirlemek neredeyse imkansız hale gelir.
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: '1.5rem' }}>
          Aslında bu kaosun bir suçlusu vardır: Overthinking! Peki nedir bu ve neden başımıza bela olur?
        </Typography>
        <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '1rem' }}>
          Overthinking Nedir?
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: '1.5rem' }}>
          Overthinking, basit bir konuyu dev bir meseleye dönüştürme sanatıdır. Yaşanan olay, kafamızda gereğinden fazla büyür ve karmaşık hale gelir. Örneğin, arkadaşınız size
          neden mesaj atmadı? “Acaba kızgın mı?" "Yanlış mı bir şey söylediniz?" "Yoksa artık sizi sevmiyor mu?” Bu tür düşünceler, aslında küçük ve basit olan bir olayı dev bir
          kriz haline getirir. Olayı büyütür, büyütür ve sonunda hiçbir yere varamazsınız. Aşırı düşünme, bazen günlük hayatımızda karşılaştığımız basit durumları da karmaşık bir
          hale getirebilir. Mesela, iş yerinde bir toplantıda konuşurken tereddüt edersiniz ve “Acaba yeterince iyi konuştum mu? İş arkadaşlarım ne düşünüyor?” gibi sorular
          kafanızda dönmeye başlar. Bu durum, performansınızı etkileyebilir ve sizin daha fazla kaygılanmanıza neden olabilir. Oysaki çoğu zaman bu tür düşünceler, gerçek bir sorun
          yaratmaz ve sadece zihinsel bir yük olarak kalır.
        </Typography>

        <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '1rem' }}>
          Overthinking’in Etkileri
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: '1.5rem' }}>
          Overthinking, başınızı ağrıtmaktan daha fazlasını yapar. Zihinsel olarak sizi yorar, kaygı ve depresyon yaratır. Bir sorunu büyütür, kafanızda dev bir karışıklığa neden
          olur. Bu karmaşa, karar verme süreçlerinizi zorlaştırabilir ve genel yaşam kalitenizi etkileyebilir.
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: '1.5rem' }}>
          Fiziksel olarak da aşırı düşünme; uyku problemleri, baş ağrıları ve mide sorunları gibi etkilerle karşılaşmanıza neden olabilir. Sürekli düşünmek uykusuzluğa yol açabilir
          çünkü geceleyin zihniniz durmak bilmez ve bu da yeterince dinlenmenizi engeller. Stres ve kaygı, sindirim sistemini etkileyebilir ve mide ağrılarına neden olabilir. Yani
          aşırı düşünmek sadece kafanızda kalmaz; tüm bedeninizi etkiler.
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: '1.5rem' }}>
          Aynı zamandadüşü, sosyal ilişkilerde de sorunlar yaratabilir. Aşırı düşünme, kişiler arası iletişimi zorlaştırabilir. Bir arkadaşınızın davranışlarını gereğinden fazla
          analiz etmek aranızda gereksiz gerginliklere neden olabilir. Bu tür durumlar, ilişkilerinizi zedeleyebilir ve sosyal çevrenizde yanlış anlamalara yol açabilir.
        </Typography>
        <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '1rem' }}>
          Overthinking Döngüsünden Nasıl Çıkılır?
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: '1.5rem' }}>
          Overthinking döngüsünden çıkmak için bazı basit ama etkili yöntemler var. İlk olarak, aşırı düşündüğünüzü fark etmekle başlıyoruz. Kafanızda dönen düşünceleri yakalayın
          ve kendinize sorun:
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: '1.5rem' }}>
          “Bu düşünce bana gerçekten fayda sağlıyor mu, yoksa sadece daha fazla strese mi yol açıyor?”
        </Typography>
        <Box component="ul" sx={{ paddingLeft: '1.5rem', marginBottom: '1.5rem' }}>
          <Typography component="li">
            <strong>Kısa Molalar Vermek,</strong> zihninizi rahatlatmak için oldukça etkili bir yöntemdir. Kısa bir yürüyüş yapın, derin bir nefes alın veya birkaç dakika sessiz
            kalın. Zihninizin bu basit egzersizlerle bir nefes almasını sağlayabilirsiniz. Ayrıca, doğal ortamlarda vakit geçirmek de zihinsel olarak rahatlamanıza yardımcı
            olabilir. Doğanın sunduğu huzur, düşüncelerinizi düzenlemenize ve kafanızdaki karışıklığı azaltmanıza yardımcı olacaktır.
          </Typography>
          <Typography component="li">
            <strong>Düşünceleri kağıda dökmek</strong> de etkili bir tekniktir. Yazmak, karmaşıklığı azaltır ve düşüncelerinizi dışa vurmanıza yardımcı olur. Bu teknik özellikle
            yazılı düşünmeyi sevenler için oldukça faydalı olabilir. Yazıya dökerek zihninizdeki karmaşayı organize edebilir ve sorunları daha net görebilirsiniz.
          </Typography>
          <Typography component="li">
            Bir diğer yöntem ise <strong>pozitif düşünceler üretmektir.</strong> Negatif düşünceler yerine pozitiflerini geliştirmek, zihninizin olumlu yönde çalışmasını sağlar.
            Örneğin bir iş projesinde karşılaştığınız zorlukları “Bu, gelişim için bir fırsat” şeklinde değerlendirebilirsiniz. Bu yaklaşım zihninizin daha yapıcı bir şekilde
            çalışmasına yardımcı olur.
          </Typography>
        </Box>

        <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '1rem' }}>
          Overthinking’i Azaltma İpuçları
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: '1.5rem' }}>
          Aşırı düşünmeyi azaltmak için şu yöntemleri deneyebilirsiniz:
        </Typography>
        <Box component="ul" sx={{ paddingLeft: '1.5rem', marginBottom: '1.5rem' }}>
          <Typography component="li">
            <strong>Sınır Koymak:</strong> Düşünmeye belirli bir süre ayırın ve bu sürenin sonunda düşünceleri bırakmayı öğrenin. Örneğin, bir konu üzerinde 15 dakika düşünmeye
            karar verebilirsiniz ve bu sürenin sonunda “Bu kadar düşünmek yeterli” diyerek konuyu kapatabilirsiniz. Bu, düşüncelerinizin kontrolünü elinizde tutmanıza yardımcı
            olur.
          </Typography>
          <Typography component="li">
            <strong>Fiziksel Aktivite:</strong> Egzersiz yaparak bedeninizi ve zihninizi rahatlatabilirsiniz. Spor yapmak, stres hormonlarını azaltır ve kendinizi daha iyi
            hissetmenizi sağlar. Ayrıca egzersiz yapmak, zihninizdeki düşünceleri dağıtmak için mükemmel bir yoldur. Her gün düzenli olarak yapılan fiziksel aktiviteler, mental
            sağlığınızı da iyileştirir.
          </Typography>
          <Typography component="li">
            <strong>Zihinsel Egzersizler:</strong> Meditasyon, yoga veya nefes egzersizleri gibi aktiviteler, aşırı düşünme ile başa çıkmanıza yardımcı olur. Bu tür egzersizler,
            zihninizin sakinleşmesini sağlar ve stres seviyelerini azaltır. Özellikle meditasyon, düşüncelerinizi gözlemlemenizi ve onları daha objektif bir şekilde
            değerlendirmenizi sağlar.
          </Typography>
          <Typography component="li">
            <strong>Sosyal Destek:</strong>Aşırı düşünme ile başa çıkmakta sosyal destek de önemli bir rol oynar. Güvendiğiniz bir arkadaşınızla veya aile bireyinizle konuşmak,
            düşüncelerinizi paylaşmak ve dışarıdan bir bakış açısı almak faydalı olabilir. Bazen bir başkasının perspektifi, kafanızdaki düşünceleri daha net bir şekilde görmenize
            olanak sağlar.
          </Typography>
        </Box>
        <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '1rem' }}>
          Overthinking masasında çatal bıçak kullanmak aslında düşündüğünüz kadar karmaşık değil.
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: '1.5rem' }}>
          Önemli olan, bu süreçte adım adım ilerlemek ve kendinize karşı nazik olmaktır. Biraz pratik yaparak ve doğru stratejileri uygulayarak, açık büfenizdeki kaosu kontrol
          altına alabilirsiniz!
        </Typography>
      </>
    ),
    author: 'Yazar Adı',
    date: '2024-09-01'
  }
];

export default blogData;
