import React, { useState } from 'react';
import { faqData } from '../data/FaqData';
import { Box, Typography, Accordion, AccordionSummary, AccordionDetails, Paper } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const FaqComponent = () => {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Box mt={2} mb={2} display="flex" justifyContent="center">
      <Paper
        sx={{
          width: '70%',
          padding: 2,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)' // Daha hafif bir gölge efekti
        }}
      >
        <Box sx={{ width: '60%' }}>
          <Typography variant="h4" textAlign="center" mb={2}>
            Sıkça Sorulan Sorular
          </Typography>
          {faqData.map((item, index) => (
            <Accordion key={index} expanded={expanded === `panel${index}`} onChange={handleChange(`panel${index}`)}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} id={`panel${index}-header`}>
                <Typography>{item.question}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography sx={{ color: 'gray' }}>{item.answer}</Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
        <Box ml={2} display="flex" justifyContent="center" alignItems="center" sx={{ width: '40%' }}>
          <img src="/images/sıksorular.png" alt="Sıkça Sorulan Sorular Görseli" style={{ maxWidth: '40%', height: 'auto' }} />
        </Box>
      </Paper>
    </Box>
  );
};

export default FaqComponent;
