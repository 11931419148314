import React from 'react';
import { Box, Grid, Typography, List, ListItem, ListItemText, Paper } from '@mui/material';

const PersonalTherapy = () => {
  return (
    <Box>
      <Box sx={{ backgroundColor: '#cccccc', padding: '20px 0', marginTop: '100px', textAlign: 'center' }}>
        <Typography
          variant="h1"
          sx={{
            color: '#fff',
            margin: '0px',
            fontSize: '35px',
            letterSpacing: '10px',
            textTransform: 'uppercase',
            marginTop: '70px',
            fontWeight: 100
          }}
        >
          BİREYSEL PSİKOLOJİK DANIŞMA
        </Typography>
      </Box>

      <Box sx={{ backgroundColor: '#f5f5f5', padding: '2rem' }}>
        <Paper sx={{ padding: '2rem', maxWidth: '1800px', margin: '0 auto', boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)' }}>
          <Grid container spacing={4}>
            <Grid item xs={12} md={9}>
              <img src="/images/bireysel.png" alt="Bireysel Terapi" style={{ width: '100%', borderRadius: '4px', marginBottom: '1rem' }} />
              <Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: '1.5rem' }}>
                Bireysel Danışmanlık: Kendinizi Keşfetmenin ve Geliştirmenin Yolu
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: '1.5rem' }}>
                Herkesin hayatında zaman zaman karşılaştığı zorluklar, çıkmazlar ve kararsızlıklar olabilir. Bu durumlar bazen içinden çıkılması zor bir hal alabilir ve kişi,
                kendini çaresiz hissedebilir. Bireysel danışmanlık, bu zamanlarda kişilere rehberlik ederek, kendi iç dünyalarını keşfetmelerine ve yaşadıkları sorunlarla başa
                çıkmalarına yardımcı olan bir destek sürecidir.
              </Typography>

              <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '1rem' }}>
                Bireysel Danışmanlık Nedir?
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: '1.5rem' }}>
                Kişilerin duygusal, psikolojik ve sosyal zorluklarla başa çıkmalarını sağlamak amacıyla sunulan profesyonel bir hizmettir. Danışmanlık sürecinde bireylerin
                yaşadıkları sorunlar, duygusal durumları ve hayatlarındaki önemli kararlar ele alınır. Bu süreçte danışanlar kendilerini daha iyi anlamayı, duygusal zorluklarını
                aşmayı ve yaşamlarında daha sağlıklı ve dengeli bir yol çizmeyi öğrenirler.
              </Typography>

              <Typography variant="body1" sx={{ marginBottom: '1.5rem', marginTop: '1.5rem' }}>
                Danışman, bireyin ihtiyaçlarına ve yaşadığı zorluklara uygun stratejiler geliştirir. Kişinin kendini daha iyi tanıması, potansiyelini keşfetmesi ve yaşamında
                pozitif değişiklikler yapması hedeflenir. Bireysel danışmanlık, kişinin yaşam kalitesini artırmak, kişisel gelişimini desteklemek ve içsel huzuru yakalamasına
                yardımcı olan güçlü bir araçtır.
              </Typography>

              <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '1rem' }}>
                Hangi Durumlarda Bireysel Danışmanlığa Başvurulmalıdır?
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: '1.5rem' }}>
                Kendi hayatında değişiklik yapmak isteyen, duygusal sorunlar yaşayan, ilişkilerinde problemler yaşayan veya kariyer planlaması yapmak isteyen kişiler bireysel
                danışmanlıktan faydalanabilir. Danışmanlık süreci, bireylerin kendilerini keşfetmelerine ve daha sağlıklı kararlar almalarına yardımcı olur.
              </Typography>

              <Typography variant="body1" sx={{ marginBottom: '1.5rem', marginTop: '1.5rem' }}>
                Bireysel danışmanlık, birçok farklı durumda faydalı olabilir. Kişisel sorunlar, duygusal zorluklar veya hayatın getirdiği büyük değişiklikler karşısında profesyonel
                destek almak, bu süreci daha sağlıklı bir şekilde atlatmanıza yardımcı olur. Danışmanlık almanız gereken bazı durumlar şunlar olabilir:
              </Typography>

              <Box component="ul" sx={{ paddingLeft: '1.5rem', marginBottom: '1.5rem' }}>
                <Typography component="li">
                  <Box component="span" sx={{ fontWeight: 'bold' }}>
                    Anksiyete:
                  </Box>{' '}
                  Kaygı bozuklukları veya gelecekle ilgili endişeler yaşıyorsanız
                </Typography>
                <Typography component="li">
                  <Box component="span" sx={{ fontWeight: 'bold' }}>
                    Stres Yönetimi:
                  </Box>{' '}
                  İş hayatı, aile veya sosyal ilişkilerden kaynaklanan stresle başa çıkmak istiyorsanız
                </Typography>
                <Typography component="li">
                  <Box component="span" sx={{ fontWeight: 'bold' }}>
                    Yaşam Değişiklikleri:
                  </Box>{' '}
                  Taşınma, boşanma, yeni bir işe başlama gibi büyük yaşam değişiklikleri karşısında zorlanıyorsanız
                </Typography>
                <Typography component="li">
                  <Box component="span" sx={{ fontWeight: 'bold' }}>
                    Kendine Güven Eksikliği:
                  </Box>{' '}
                  Özgüven problemi yaşıyorsanız ve sosyal ilişkilerde zorlanıyorsanız
                </Typography>
                <Typography component="li">
                  <Box component="span" sx={{ fontWeight: 'bold' }}>
                    Karar Verme Zorlukları:
                  </Box>{' '}
                  Hayatınızda önemli kararlar alırken kararsız kalıyor ve destek arıyorsanız
                </Typography>
                <Typography component="li">
                  <Box component="span" sx={{ fontWeight: 'bold' }}>
                    Kişisel Gelişim:
                  </Box>{' '}
                  Kendi potansiyelinizi keşfetmek, kişisel gelişiminizi desteklemek ve hayatınızda pozitif değişiklikler yapmak istiyorsanız
                </Typography>
              </Box>

              <Typography variant="body1" sx={{ marginBottom: '1.5rem' }}>
                Bu durumlar, bireysel danışmanlık sürecinin fayda sağlayabileceği bazı örneklerdir. Danışmanlık, bireylerin daha mutlu, dengeli ve tatmin edici bir yaşam
                sürmelerine katkıda bulunur.
              </Typography>

              <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '1rem' }}>
                Danışmanlık Sürecinde Neler Beklenmelidir?
              </Typography>

              <Box component="ul" sx={{ paddingLeft: '1.5rem', marginBottom: '1.5rem' }}>
                <Typography component="li">
                  <Box component="span" sx={{ fontWeight: 'bold' }}>
                    Güvenli ve Gizli Bir Ortam:
                  </Box>{' '}
                  Danışanın, duygularını ve düşüncelerini güvenle ifade edebileceği güvenli bir ortamda danışmanlık alınır.
                </Typography>
                <Typography component="li">
                  <Box component="span" sx={{ fontWeight: 'bold' }}>
                    Özelleştirilmiş Yaklaşım:
                  </Box>{' '}
                  Bireyin özel ihtiyaçlarına ve sorunlarına uygun terapi yöntemleri geliştirilir.
                </Typography>
                <Typography component="li">
                  <Box component="span" sx={{ fontWeight: 'bold' }}>
                    Kendini Keşfetme:
                  </Box>{' '}
                  Danışmanlık süreci, kişinin kendini daha iyi tanımasına, güçlü ve zayıf yönlerini keşfetmesine yardımcı olur.
                </Typography>
                <Typography component="li">
                  <Box component="span" sx={{ fontWeight: 'bold' }}>
                    Destekleyici Rehberlik:
                  </Box>{' '}
                  Danışman, süreç boyunca bireye rehberlik eder ve onu cesaretlendirir.
                </Typography>
                <Typography component="li">
                  <Box component="span" sx={{ fontWeight: 'bold' }}>
                    Kişisel Gelişim:
                  </Box>{' '}
                  Birey, kendine koyduğu hedeflere ulaşmak ve daha dengeli bir yaşam sürmek için gerekli becerileri geliştirir.
                </Typography>
              </Box>

              <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '1rem' }}>
                Kendi Yolunuzu Bulmanıza Yardımcı Bir Süreç
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: '1.5rem' }}>
                Bireysel danışmanlık, yaşamınızın her alanında daha sağlıklı ve mutlu olmanıza yardımcı olabilir. Bu süreç sadece mevcut sorunları çözmekle kalmaz, aynı zamanda
                gelecekte karşılaşabileceğiniz zorluklarla başa çıkma yeteneklerinizi de geliştirir. Kendinizi keşfetmek, yaşamınızda pozitif değişiklikler yapmak ve daha tatmin
                edici bir yaşam sürmek için bireysel danışmanlık önemli bir adımdır.
              </Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '1rem' }}>
                Hizmetlerimiz
              </Typography>
              <List sx={{ backgroundColor: '#fafafa', padding: '1rem', borderRadius: '4px' }}>
                <ListItem button component="a" href="/cocuk-ergen-danismanligi">
                  <ListItemText primary="Çocuk & Ergen Danışmanlığı" />
                </ListItem>
                <ListItem button component="a" href="/cift-ve-aile-danismanligi">
                  <ListItemText primary="Aile ve Evlilik Danışmanlığı" />
                </ListItem>
                <ListItem button component="a" href="/ebeveyn-danismanligi">
                  <ListItemText primary="Ebeveyn Danışmanlığı" />
                </ListItem>
                <ListItem button component="a" href="/sinav-danismanligi">
                  <ListItemText primary="Sınav Danışmanlığı" />
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </Box>
  );
};

export default PersonalTherapy;
