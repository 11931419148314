export const faqData = [
  {
    question: 'Seanslar Ne Kadar Sürer?',
    answer: 'Terapi seansları genellikle 50-60 dakika sürer. Bu süre, seansın içeriğine ve ihtiyaçlarınıza bağlı olarak değişebilir.'
  },
  {
    question: 'Ne Sıklıkla Görüşülür?',
    answer:
      'Seans sıklığı kişisel ihtiyaçlara ve terapötik hedeflere göre değişir. Genel olarak haftada bir veya iki haftada bir seans yapılır. Ancak bazı durumlarda daha sık veya daha seyrek seanslar gerekebilir.'
  },
  {
    question: 'İyileşmek için kaç seans gereklidir?',
    answer: 'Bu durum birçok kritere göre değişkenlik gösterir. Kişisel ihtiyaçlara ve terapinin içeriğine bağlıdır. Genellikle birkaç hafta ile birkaç ay sürebilir.'
  },
  {
    question: 'Psikolojik danışmanlığa ihtiyaç duyduğumu nasıl anlarım?',
    answer:
      'Sürekli üzüntü, kaygı veya stres yaşıyorsanız, geçmiş travmalar sizi etkiliyorsa veya kendi başınıza çözemediğiniz sorunlar varsa, psikolojik danışmanlık hizmeti almak size yardımcı olabilir. '
  }
];
