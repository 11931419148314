import React from 'react';
import { Box, Typography, Grid, Link } from '@mui/material';
import InstagramIcon from '@mui/icons-material/Instagram';

const Footer = () => {
  return (
    <Box sx={{ backgroundColor: '#333', color: '#fff', padding: '2rem 0', mt: 'auto', textAlign: 'center' }}>
      <Box sx={{ maxWidth: '1200px', margin: '0 auto' }}>
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} md={4}>
            <img src="images/logo.png" alt="Logo" style={{ maxHeight: '100px', cursor: 'pointer', marginLeft: '30px' }} />
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '1rem', color: '#676767' }}>
              <Link href="https://www.instagram.com/psk.polenozudogru" color="inherit" target="_blank" rel="noopener noreferrer">
                <InstagramIcon sx={{ fontSize: '24px', marginRight: '8px', color: '#676767' }} />
              </Link>
            </Box>
            <Typography variant="body2" sx={{ color: '#676767' }}>
              GSM: (0532) 492 23 43
            </Typography>
            <Typography variant="body2" sx={{ color: '#676767' }}>
              E-posta: psk.dan.polenozudogru@gmail.com
            </Typography>
          </Grid>
          <Grid sx={{ marginTop: '40px' }} item xs={12} md={4}>
            <Typography variant="h6" gutterBottom>
              HİZMETLERİMİZ
            </Typography>
            <Link
              href="/bireysel-psikolojik-danisma"
              sx={{ color: '#676767', textDecoration: 'none', fontSize: '12px', textTransform: 'uppercase', display: 'block', marginBottom: '4px' }}
            >
              BİREYSEL PSİKOLOJİK DANIŞMA
            </Link>
            <Link
              href="/cocuk-ergen-danismanligi"
              sx={{ color: '#676767', textDecoration: 'none', fontSize: '12px', textTransform: 'uppercase', display: 'block', marginBottom: '4px' }}
            >
              ÇOCUK & ERGEN DANIŞMANLIĞI
            </Link>
            <Link
              href="/cift-ve-aile-danismanligi"
              sx={{ color: '#676767', textDecoration: 'none', fontSize: '12px', textTransform: 'uppercase', display: 'block', marginBottom: '4px' }}
            >
              AİLE VE EVELİLİK DANIŞMANLIĞI
            </Link>
            <Link
              href="/ebeveyn-danismanligi"
              sx={{ color: '#676767', textDecoration: 'none', fontSize: '12px', textTransform: 'uppercase', display: 'block', marginBottom: '4px' }}
            >
              EBEVEYN DANIŞMANLIĞI
            </Link>
            <Link href="/sinav-danismanligi" sx={{ color: '#676767', textDecoration: 'none', fontSize: '12px', textTransform: 'uppercase', display: 'block', marginBottom: '4px' }}>
              SINAV DANIŞMANLIĞI
            </Link>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Footer;
