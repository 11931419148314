import React from 'react';
import { Box, Grid, Typography, Paper } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import blogData from '../data/BlogData';
import BlogCard from '../components/BlogCard';

const Blog = () => {
  const navigate = useNavigate();

  const handleCardClick = (subject) => {
    navigate(`/blog/${subject}`);
  };

  return (
    <Box>
      <Box sx={{ backgroundColor: '#cccccc', padding: '20px 0', marginTop: '100px', textAlign: 'center' }}>
        <Typography
          variant="h1"
          sx={{
            color: '#fff',
            margin: '0px',
            fontSize: '35px',
            letterSpacing: '10px',
            textTransform: 'uppercase',
            marginTop: '70px',
            fontWeight: 100
          }}
        >
          BLOG
        </Typography>
      </Box>

      <Box sx={{ backgroundColor: '#f5f5f5', padding: '2rem' }}>
        <Paper sx={{ padding: '2rem', maxWidth: '1800px', margin: '0 auto', boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)' }}>
          <Grid container spacing={2} justifyContent="center">
            {blogData.map((blog, index) => (
              <Grid item key={index} xs={12} sm={6} md={4}>
                <div onClick={() => handleCardClick(blog.subject)}>
                  {' '}
                  <BlogCard title={blog.title} image={blog.image} />
                </div>
              </Grid>
            ))}
          </Grid>
        </Paper>
      </Box>
    </Box>
  );
};

export default Blog;
