import React from 'react';
import { Box, Grid, Typography, List, ListItem, ListItemText, Paper, Avatar } from '@mui/material';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

const AboutMe = () => {
  return (
    <Box>
      <Box sx={{ backgroundColor: '#cccccc', padding: '20px 0', marginTop: '100px', textAlign: 'center' }}>
        <Typography
          variant="h1"
          sx={{
            color: '#fff',
            margin: '0px',
            fontSize: '35px',
            letterSpacing: '10px',
            textTransform: 'uppercase',
            marginTop: '70px',
            fontWeight: 100
          }}
        >
          HAKKIMDA
        </Typography>
      </Box>

      <Box sx={{ backgroundColor: '#f5f5f5', padding: '2rem' }}>
        <Paper sx={{ padding: '2rem', maxWidth: '1200px', margin: '0 auto', boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)' }}>
          <Grid container spacing={4}>
            {/* Sol taraf: Profil fotoğrafı ve isim */}
            <Grid item xs={12} md={4} sx={{ textAlign: 'center' }}>
              <Avatar src="/images/profile.jpg" sx={{ width: 250, height: 250, margin: '0 auto' }} />
            </Grid>
            <Grid item xs={12} md={8} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
              <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
                Polen Özüdoğru
              </Typography>
              <Typography variant="body1" color="textSecondary">
                Psikolojik Danışman
              </Typography>
              <Box mt={2} onClick={() => window.open('https://www.linkedin.com/in/polen-ozudogru', '_blank')}>
                <LinkedInIcon style={{ width: 24, height: 24 }} />
              </Box>
            </Grid>
          </Grid>
          {/* Eğitim ve Uzmanlık Alanları yan yana */}
          <Grid container spacing={4} sx={{ marginTop: '2rem' }}>
            <Grid item xs={12} md={6}>
              <Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: '1rem', marginLeft: '1rem' }}>
                Eğitim
              </Typography>
              <List>
                <ListItem>
                  <ListItemText primary="Lisans" secondary="Rehberlik ve Psikolojik Danışmanlık, Doğu Akdeniz Üniversitesi" />
                </ListItem>
                <ListItem>
                  <ListItemText primary="Yüksek Lisans" secondary="Aile ve Evlilik Terapisi, İstanbul Aydın Üniversitesi" />
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: '1rem' }}>
                Uzmanlık Alanları
              </Typography>
              <Grid container spacing={1}>
                {[
                  'Kaygı',
                  'Stres',
                  'Ayrılık',
                  'İlişki Problemleri',
                  'Ailevi Sorunlar',
                  'Evlilik İçi Çatışmalar',
                  'Evlilik ve Öncesi Konular',
                  'Boşanma',
                  'Sınav Kaygısı',
                  'Kariyer Yönelimi ve Gelişimi'
                ].map((item) => (
                  <Grid item xs={6} key={item}>
                    <Typography variant="body1">• {item}</Typography>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
          {/* Hakkında bölümü */}
          <Grid item xs={12} sx={{ marginTop: '2rem' }}>
            <Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: '1rem' }}>
              Hakkında
            </Typography>
            <Typography variant="body1" sx={{ lineHeight: '1.7', color: '#333' }}>
              2019 yılında Doğu Akdeniz Üniversitesi, Psikolojik Danışmanlık ve Rehberlik bölümünden tam burslu mezun olmuştur. Kişinin ihtiyacına uygun bir biçimde, bilişsel
              davranışçı terapi ve çözüm odaklı terapi ekollerini kullanmaktadır. Hem bireysel danışmanlık hem de çift danışmanlığı alanında 500'ü aşkın seans gerçekleştirmiş olup
              4 yıldır aktif olarak danışan görmektedir. Şu anda İstanbul Aydın Üniversitesi'nde aile ve ilişki danışmanlığı yüksek lisansına devam etmektedir.
            </Typography>
          </Grid>
        </Paper>
      </Box>
    </Box>
  );
};

export default AboutMe;
