import React from 'react';
import Slider from 'react-slick';
import { Box, Typography, Button, Container } from '@mui/material';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const services = [
  { title: 'Bireysel Psikolojik Danışma', description: 'Bireysel terapiler hakkında bilgi alın.', link: '/bireysel-psikolojik-danisma', image: '/images/bireysel.png' },
  { title: 'Aile ve Evlilik Danışmanlığı', description: 'Çift terapileri hakkında bilgi alın.', link: '/cift-ve-aile-danismanligi', image: '/images/çift.jpg' },
  { title: 'Çocuk ve Ergen Danışmanlığı', description: 'Çocuk ve ergen terapileri hakkında bilgi alın.', link: '/cocuk-ergen-danismanligi', image: '/images/ergen.png' },
  { title: 'Ebeveyn Danışmanlığı', description: 'Ebeveyn danışmanlığı hakkında bilgi alın.', link: '/ebeveyn-danismanligi', image: '/images/ebeveyn.png' },
  { title: 'Sınav Danışmanlığı', description: 'Sınav danışmanlığı hakkında bilgi alın.', link: '/sinav-danismanligi', image: '/images/sınav.png' }
];

const ServiceSlider = () => {
  const sliderRef = React.useRef(null);

  const next = () => {
    sliderRef.current.slickNext();
  };

  const previous = () => {
    sliderRef.current.slickPrev();
  };

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 5000
  };

  return (
    <div style={{ position: 'relative' }}>
      <Slider ref={sliderRef} {...settings}>
        {services.map((service, index) => (
          <Box
            key={index}
            className="relative flex items-center justify-center h-[80vh] text-white text-center"
            sx={{
              backgroundImage: `url(${service.image})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              color: 'white',
              textAlign: 'center',
              position: 'relative',
              height: { xs: '50vh', sm: '60vh', md: '80vh' }
            }}
          >
            <Container className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
              <Typography variant="h4" component="h1" gutterBottom sx={{ fontSize: { xs: '1.5rem', sm: '2rem', md: '3rem' } }}>
                {service.title}
              </Typography>
              <Typography variant="h6" component="p" gutterBottom sx={{ fontSize: { xs: '1rem', sm: '1.25rem', md: '1.5rem' } }}>
                {service.description}
              </Typography>
              <a href={service.link} style={{ textDecoration: 'none' }}>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  sx={{
                    fontSize: { xs: '0.75rem', sm: '0.875rem', md: '1rem' },
                    textTransform: 'none',
                    backgroundColor: 'white',
                    color: 'black',
                    '&:hover': {
                      backgroundColor: 'gray',
                      color: 'white'
                    }
                  }}
                >
                  Detaylı Bilgi
                </Button>
              </a>
            </Container>
          </Box>
        ))}
      </Slider>
      <button
        onClick={previous}
        style={{
          position: 'absolute',
          top: '50%',
          left: '15px',
          transform: 'translateY(-50%)',
          zIndex: 2,
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          color: 'white',
          border: 'none',
          borderRadius: '50%',
          width: '30px',
          height: '30px',
          fontSize: '20px',
          display: { xs: 'block', sm: 'block', md: 'none' }
        }}
      >
        ‹
      </button>
      <button
        onClick={next}
        style={{
          position: 'absolute',
          top: '50%',
          right: '15px',
          transform: 'translateY(-50%)',
          zIndex: 2,
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          color: 'white',
          border: 'none',
          borderRadius: '50%',
          width: '30px',
          height: '30px',
          fontSize: '20px',
          display: { xs: 'block', sm: 'block', md: 'none' }
        }}
      >
        ›
      </button>
    </div>
  );
};

export default ServiceSlider;
