import React from 'react';
import { Box, Grid, Typography, List, ListItem, ListItemText, Paper } from '@mui/material';

const ExamTheapy = () => {
  return (
    <Box>
      <Box sx={{ backgroundColor: '#cccccc', padding: '20px 0', marginTop: '100px', textAlign: 'center' }}>
        <Typography
          variant="h1"
          sx={{
            color: '#fff',
            margin: '0px',
            fontSize: '35px',
            letterSpacing: '10px',
            textTransform: 'uppercase',
            marginTop: '70px',
            fontWeight: 100
          }}
        >
          SINAV DANIŞMANLIĞI
        </Typography>
      </Box>

      <Box sx={{ backgroundColor: '#f5f5f5', padding: '2rem' }}>
        <Paper sx={{ padding: '2rem', maxWidth: '1800px', margin: '0 auto', boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)' }}>
          <Grid container spacing={4}>
            <Grid item xs={12} md={9}>
              <img src="/images/sınav.png" alt="Sınav Danışmanlığı" style={{ width: '100%', borderRadius: '4px', marginBottom: '1rem' }} />
              <Box>
                <Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: '1rem' }}>
                  Sınav Danışmanlığı: Başarıya Giden Yolda Güvenilir Bir Rehber
                </Typography>
                <Typography variant="body1" sx={{ marginBottom: '1.5rem' }}>
                  Sınavlar, öğrencilerin eğitim hayatlarındaki en önemli dönüm noktalarından biridir. Ancak bu dönüm noktası, stres, kaygı ve baskı gibi duygusal zorlukları da
                  beraberinde getirir. Sınav danışmanlığı bu süreçte öğrencilere rehberlik ederek, hem akademik başarıyı artırmayı hem de duygusal dengeyi korumayı amaçlar.
                </Typography>

                <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '1rem' }}>
                  Sınav Danışmanlığı Nedir?
                </Typography>
                <Typography variant="body1" sx={{ marginBottom: '1.5rem' }}>
                  Öğrencilerin sınav sürecinde karşılaştıkları zorluklarla başa çıkmalarını sağlamak ve sınav performanslarını en üst düzeye çıkarmak amacıyla sunulan bir
                  danışmanlık hizmetidir. Bu süreçte öğrencilerin akademik hedefleri, çalışma alışkanlıkları ve duygusal durumları göz önünde bulundurularak bireysel bir yol
                  haritası oluşturulur.
                </Typography>

                <Typography variant="body1" sx={{ marginBottom: '1.5rem' }}>
                  Danışman, öğrencinin sınav kaygısını yönetmesine, etkili çalışma yöntemleri geliştirmesine ve motivasyonunu artırmasına yardımcı olur. Sınav danışmanlığı sadece
                  akademik başarıyı hedeflemekle kalmaz, aynı zamanda öğrencinin özgüvenini artırmayı ve sınav sürecinde daha dengeli bir yaklaşım geliştirmesini sağlar.
                </Typography>
                <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '1rem' }}>
                  Hangi Durumlarda Sınav Danışmanlığına Başvurulmalıdır?
                </Typography>

                <Typography variant="body1" sx={{ marginBottom: '1.5rem' }}>
                  Sınav süreci her öğrenci için farklı zorluklar barındırır. Bu zorluklar, öğrencilerin performansını olumsuz etkileyebilir ve akademik hedeflerine ulaşmalarını
                  zorlaştırabilir. Aşağıdaki durumlar, sınav danışmanlığına başvurulmasını gerektirebilecek durumlardan bazılarıdır:
                </Typography>

                <Box component="ul" sx={{ paddingLeft: '1.5rem', marginBottom: '1.5rem' }}>
                  <Typography component="li">
                    <strong>Sınav Kaygısı:</strong> Sınav öncesinde aşırı stres ve kaygı yaşamak
                  </Typography>
                  <Typography component="li">
                    <strong>Düşük Motivasyon:</strong> Çalışma isteğinde azalma, hedeflerden uzaklaşmak
                  </Typography>
                  <Typography component="li">
                    <strong>Zaman Yönetimi Sorunları:</strong> Çalışma saatlerini verimli kullanamama, planlama yapmada zorlanmak
                  </Typography>
                  <Typography component="li">
                    <strong>Akademik Hedef Belirleme:</strong> Hangi alanlarda çalışılması gerektiğini belirlemek
                  </Typography>
                  <Typography component="li">
                    <strong>Kendine Güven Eksikliği:</strong> Sınavda başarılı olamayacağına dair inanç, düşük özgüven
                  </Typography>
                </Box>
                <Typography variant="body1" sx={{ marginBottom: '1.5rem' }}>
                  Bu tür durumlar, sınav danışmanlığının fayda sağlayabileceği bazı örneklerdir. Danışmanlık süreci, öğrencilerin sınav sürecinde daha kontrollü ve özgüvenli
                  olmalarına yardımcı olur.
                </Typography>

                <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '1rem' }}>
                  Danışmanlık Sürecinde Neler Beklenmelidir?
                </Typography>

                <Box component="ul" sx={{ paddingLeft: '1.5rem', marginBottom: '1.5rem' }}>
                  <Typography component="li">
                    <strong>Bireysel Yaklaşım:</strong> Danışmanlık, her öğrencinin kişisel ihtiyaçlarına ve hedeflerine göre şekillendirilir.
                  </Typography>
                  <Typography component="li">
                    <strong>Motivasyon Artırıcı Teknikler:</strong> Öğrencinin motivasyonunu yükseltmek için özel stratejiler geliştirilir.
                  </Typography>
                  <Typography component="li">
                    <strong>Zaman Yönetimi ve Planlama:</strong> Verimli çalışma planları hazırlanır, zaman yönetimi konusunda rehberlik edilir.
                  </Typography>
                  <Typography component="li">
                    <strong>Kaygı Yönetimi:</strong> Sınav kaygısını azaltmak için nefes teknikleri ve rahatlama egzersizleri öğretilir.
                  </Typography>
                  <Typography component="li">
                    <strong>Akademik Destek:</strong> Öğrencinin zayıf olduğu alanlarda akademik destek sağlanır, hedeflere ulaşmak için gerekli adımlar atılır.
                  </Typography>
                </Box>

                <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '1rem' }}>
                  Başarıya Giden Yolda Güvenilir Bir Rehber
                </Typography>
                <Typography variant="body1" sx={{ marginBottom: '1.5rem' }}>
                  Sınav danışmanlığı sadece sınav sürecinde değil, öğrencinin tüm eğitim hayatı boyunca ihtiyaç duyabileceği becerileri geliştirmesine yardımcı olur. Bu süreç
                  öğrencinin hem akademik hem de duygusal olarak güçlenmesini sağlar. Unutmayın, sınavlar hayatın önemli bir parçasıdır, ancak bu süreci daha az stresli ve daha
                  verimli geçirmek mümkündür. Sınav danışmanlığı bu yolda size rehberlik eder ve başarıya giden yolda güvenilir bir destek sunar.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '1rem' }}>
                Hizmetlerimiz
              </Typography>
              <List sx={{ backgroundColor: '#fafafa', padding: '1rem', borderRadius: '4px' }}>
                <ListItem button component="a" href="/bireysel-psikolojik-danisma">
                  <ListItemText primary="Bireysel Psikolojik Danışma" />
                </ListItem>
                <ListItem button component="a" href="/cocuk-ergen-danismanligi">
                  <ListItemText primary="Çocuk & Ergen Danışmanlığı" />
                </ListItem>
                <ListItem button component="a" href="/cift-ve-aile-danismanligi">
                  <ListItemText primary="Aile ve Evlilik Danışmanlığı" />
                </ListItem>
                <ListItem button component="a" href="/ebeveyn-danismanligi">
                  <ListItemText primary="Ebeveyn Danışmanlığı" />
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </Box>
  );
};

export default ExamTheapy;
