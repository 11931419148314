import React, { useState, useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Box, Menu, MenuItem } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';

const Header = () => {
  const [elevate, setElevate] = useState(false);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  const [isMobileSubMenuOpen, setIsMobileSubMenuOpen] = useState(false); // Mobil alt menü durumu

  const navigate = useNavigate();

  const handleScroll = () => {
    if (window.scrollY > 50) {
      setElevate(true);
    } else {
      setElevate(false);
    }
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleNavigateHome = () => {
    navigate('/');
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
    setIsSubMenuOpen(true);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setIsSubMenuOpen(false);
  };

  const handleMobileSubMenuToggle = () => {
    setIsMobileSubMenuOpen(!isMobileSubMenuOpen);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const drawer = (
    <List>
      <ListItem button component={Link} to="/">
        <ListItemText primary="ANASAYFA" />
      </ListItem>
      <ListItem button component={Link} to="/hakkımda">
        <ListItemText primary="HAKKIMDA" />
      </ListItem>
      <ListItem button onClick={handleMobileSubMenuToggle}>
        <ListItemText primary="HİZMETLERİMİZ" />
        {isMobileSubMenuOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={isMobileSubMenuOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem button component={Link} to="/bireysel-psikolojik-danisma" sx={{ pl: 4 }}>
            <ListItemText primary="Bireysel Psikolojik Danışma" />
          </ListItem>
          <ListItem button component={Link} to="/cocuk-ergen-danismanligi" sx={{ pl: 4 }}>
            <ListItemText primary="Çocuk & Ergen Danışmanlığı" />
          </ListItem>
          <ListItem button component={Link} to="/cift-ve-aile-danismanligi" sx={{ pl: 4 }}>
            <ListItemText primary="Aile ve Evlilik Danışmanlığı" />
          </ListItem>
          <ListItem button component={Link} to="/ebeveyn-danismanligi" sx={{ pl: 4 }}>
            <ListItemText primary="Ebeveyn Danışmanlığı" />
          </ListItem>
          <ListItem button component={Link} to="/sinav-danismanligi" sx={{ pl: 4 }}>
            <ListItemText primary="Sınav Danışmanlığı" />
          </ListItem>
        </List>
      </Collapse>
      <ListItem button component={Link} to="/blog">
        <ListItemText primary="BLOG" />
      </ListItem>
      <ListItem button component={Link} to="/iletisim">
        <ListItemText primary="İLETİŞİM" />
      </ListItem>
    </List>
  );

  return (
    <>
      <AppBar
        position="fixed"
        elevation={elevate ? 4 : 0}
        sx={{
          backgroundColor: elevate ? 'rgba(0, 0, 0, 0.8)' : 'rgba(0, 0, 0, 0.5)',
          transition: 'background-color 0.3s ease'
        }}
      >
        <Container>
          <Toolbar sx={{ minHeight: '100px' }}>
            <IconButton edge="start" color="inherit" aria-label="menu" onClick={handleDrawerToggle} sx={{ display: { xs: 'block', sm: 'none' } }}>
              <MenuIcon />
            </IconButton>
            <img src="images/logo.png" alt="Logo" style={{ maxHeight: '100px', cursor: 'pointer' }} onClick={handleNavigateHome} />
            <Box sx={{ flexGrow: 1 }} />
            <Button color="inherit" sx={{ display: { xs: 'none', sm: 'block' } }} component={Link} to="/">
              ANASAYFA
            </Button>
            <Button color="inherit" sx={{ display: { xs: 'none', sm: 'block' } }} component={Link} to="/hakkımda">
              HAKKIMDA
            </Button>
            <Button color="inherit" sx={{ display: { xs: 'none', sm: 'block' }, position: 'relative', '&:hover': { color: '#FF0000' } }} onMouseEnter={handleMenuClick}>
              HİZMETLERİMİZ
            </Button>
            <Button color="inherit" sx={{ display: { xs: 'none', sm: 'block' } }} component={Link} to="/blog">
              BLOG
            </Button>
            <Button color="inherit" sx={{ display: { xs: 'none', sm: 'block' } }} component={Link} to="/iletisim">
              İLETİŞİM
            </Button>
            <Menu
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
              MenuListProps={{ onMouseLeave: handleMenuClose }}
              sx={{
                '& .MuiPaper-root': {
                  backgroundColor: '#333',
                  color: '#FFFFFF',
                  marginTop: '10px'
                }
              }}
            >
              <MenuItem component={Link} to="/bireysel-psikolojik-danisma" onClick={handleMenuClose} sx={{ '&:hover': { backgroundColor: '#333333' } }}>
                Bireysel Psikolojik Danışma
              </MenuItem>
              <MenuItem component={Link} to="/cocuk-ergen-danismanligi" onClick={handleMenuClose} sx={{ '&:hover': { backgroundColor: '#333333' } }}>
                Çocuk & Ergen Danışmanlığı
              </MenuItem>
              <MenuItem component={Link} to="/cift-ve-aile-danismanligi" onClick={handleMenuClose} sx={{ '&:hover': { backgroundColor: '#333333' } }}>
                Aile ve Evlilik Danışmanlığı
              </MenuItem>
              <MenuItem component={Link} to="/ebeveyn-danismanligi" onClick={handleMenuClose} sx={{ '&:hover': { backgroundColor: '#333333' } }}>
                Ebeveyn Danışmanlığı
              </MenuItem>
              <MenuItem component={Link} to="/sinav-danismanligi" onClick={handleMenuClose} sx={{ '&:hover': { backgroundColor: '#333333' } }}>
                Sınav Danışmanlığı
              </MenuItem>
            </Menu>
          </Toolbar>
        </Container>
      </AppBar>
      <Drawer
        anchor="top"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': {
            marginTop: '64px',
            backgroundColor: 'rgba(128, 128, 128, 0.9)'
          }
        }}
      >
        {drawer}
      </Drawer>
    </>
  );
};

export default Header;
