import React from 'react';
import { Box, Grid, Typography, List, ListItem, ListItemText, Paper } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import blogData from '../data/BlogData';

const BlogDetail = () => {
  const { subject } = useParams();
  const blogDetail = blogData.find((blog) => blog.subject === subject);

  if (!blogDetail) {
    return <div>Blog detayı bulunamadı!</div>;
  }

  return (
    <Box>
      {/* Gri Bar */}
      <Box sx={{ backgroundColor: '#cccccc', padding: '20px 0', marginTop: '100px', textAlign: 'center' }}>
        <Typography
          variant="h1"
          sx={{
            color: '#fff',
            margin: '0px',
            fontSize: '35px',
            letterSpacing: '10px',
            textTransform: 'uppercase',
            marginTop: '70px',
            fontWeight: 100
          }}
        >
          {blogDetail.title}
        </Typography>
      </Box>
      <Box sx={{ backgroundColor: '#f5f5f5', padding: '2rem' }}>
        <Paper sx={{ padding: '2rem', maxWidth: '1800px', margin: '0 auto', boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)' }}>
          <Grid container spacing={4}>
            <Grid item xs={12} md={9}>
              <img src={blogDetail.image} alt={blogDetail.title} style={{ width: '100%', borderRadius: '4px', marginBottom: '1rem' }} />
              <Typography variant="body1" sx={{ color: '#333', lineHeight: '1.7' }}>
                {blogDetail.content}
              </Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '1rem' }}>
                Diğer Yazılar
              </Typography>
              <List sx={{ backgroundColor: '#fafafa', padding: '1rem', borderRadius: '4px' }}>
                {blogData.map((blog) => (
                  <ListItem button component={Link} to={`/blog/${blog.subject}`} key={blog.id}>
                    <ListItemText primary={blog.title} />
                  </ListItem>
                ))}
              </List>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </Box>
  );
};

export default BlogDetail;
