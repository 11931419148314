import React from 'react';
import { Box, Grid, Typography, List, ListItem, ListItemText, Paper } from '@mui/material';

const ChildTherapy = () => {
  return (
    <Box>
      <Box sx={{ backgroundColor: '#cccccc', padding: '20px 0', marginTop: '100px', textAlign: 'center' }}>
        <Typography
          variant="h1"
          sx={{
            color: '#fff',
            margin: '0px',
            fontSize: '35px',
            letterSpacing: '10px',
            textTransform: 'uppercase',
            marginTop: '70px',
            fontWeight: 100
          }}
        >
          ÇOCUK VE ERGEN DANIŞMANLIĞI
        </Typography>
      </Box>

      <Box sx={{ backgroundColor: '#f5f5f5', padding: '2rem' }}>
        <Paper sx={{ padding: '2rem', maxWidth: '1800px', margin: '0 auto', boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)' }}>
          <Grid container spacing={4}>
            <Grid item xs={12} md={9}>
              <img src="/images/ergen.png" alt="Çocuk ve Ergen Danışmanlığı" style={{ width: '100%', borderRadius: '4px', marginBottom: '1rem' }} />
              <Box>
                <Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: '1rem' }}>
                  Çocuk ve Ergen Terapisi: Geleceğin Sağlıklı Yetişkinleri İçin İlk Adım
                </Typography>
                <Typography variant="body1" sx={{ marginBottom: '1.5rem' }}>
                  Çocukluk ve ergenlik dönemleri, bir bireyin hayatındaki en kritik ve şekillendirici dönemlerdir. Bu dönemlerde yaşanan zorluklar, kişinin duygusal ve sosyal
                  gelişimlerini etkileyebilir. Çocuk ve ergen terapisi, bu zorlukların üstesinden gelmelerine yardımcı olmak amacıyla uygulanan bir terapi türüdür.
                </Typography>

                <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '1rem' }}>
                  Çocuk ve Ergen Terapisi Nedir?
                </Typography>
                <Typography variant="body1" sx={{ marginBottom: '1.5rem' }}>
                  Çocukların ve gençlerin duygusal, davranışsal ve sosyal sorunlarını ele almak için özel olarak tasarlanmış bir terapi türüdür. Terapistler, çocukların ve
                  ergenlerin gelişimsel ihtiyaçlarını, duygusal zorluklarını ve sosyal ilişkilerini dikkate alarak onlara destek olurlar. Bu terapi türü çocukların kendilerini daha
                  iyi ifade etmelerine, duygusal zorluklarla başa çıkmalarına ve sosyal becerilerini geliştirmelerine yardımcı olur.
                </Typography>

                <Typography variant="body1" sx={{ marginBottom: '1.5rem' }}>
                  Terapistler, oyun terapisi, sanat terapisi ve bilişsel-davranışçı terapi gibi çeşitli yaklaşımlar kullanarak çocukların ve ergenlerin duygusal ve davranışsal
                  sorunlarını çözmelerine yardımcı olurlar. Bu süreç, hem çocukların hem de ailelerinin yaşadığı zorlukları daha iyi anlamalarını ve bu zorluklarla nasıl başa
                  çıkacaklarını öğrenmelerini sağlar.
                </Typography>

                <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '1rem' }}>
                  Hangi Durumlarda Çocuk ve Ergen Terapisine Başvurulmalıdır?
                </Typography>
                <Typography variant="body1" sx={{ marginBottom: '1.5rem' }}>
                  Çocuklar ve ergenler, büyüme ve gelişim süreçlerinde birçok farklı zorlukla karşılaşabilirler. Bu zorluklar onların günlük yaşamlarını, okul başarılarını ve
                  sosyal ilişkilerini etkileyebilir. Aşağıdaki durumlar, çocuk ve ergen terapisine başvurulmasını gerektirebilecek durumlardan bazılarıdır:
                </Typography>

                <Box component="ul" sx={{ paddingLeft: '1.5rem', marginBottom: '1.5rem' }}>
                  <Typography component="li">
                    <strong>Duygusal Zorluklar:</strong> Depresyon, kaygı, öfke patlamaları veya sürekli üzüntü gibi duygusal zorluklar yaşayan çocuklar ve ergenler
                  </Typography>
                  <Typography component="li">
                    <strong>Davranış Problemleri:</strong> Saldırganlık, kurallara uymama, okulda veya evde sürekli sorun çıkarma gibi davranış problemleri
                  </Typography>
                  <Typography component="li">
                    <strong>Aile İçi Sorunlar:</strong> Boşanma, aile üyeleri arasında çatışmalar veya kayıplar nedeniyle yaşanan duygusal zorluklar
                  </Typography>
                  <Typography component="li">
                    <strong>Akademik Zorluklar:</strong> Dikkat eksikliği, öğrenme güçlükleri veya okula uyum sağlama konusunda yaşanan problemler
                  </Typography>
                  <Typography component="li">
                    <strong>Sosyal Çekilme:</strong> Arkadaş edinme zorlukları, sosyal fobi veya dışlanma hissi gibi sosyal problemler
                  </Typography>
                </Box>

                <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '1rem' }}>
                  Terapiden Neler Beklenmelidir?
                </Typography>

                <Box component="ul" sx={{ paddingLeft: '1.5rem', marginBottom: '1.5rem' }}>
                  <Typography component="li">
                    <strong>Güvenli ve Destekleyici Bir Ortam:</strong> Çocukların ve ergenlerin duygularını güvenle ifade edebilecekleri destekleyici bir ortam sağlanır.
                  </Typography>
                  <Typography component="li">
                    <strong>Özelleştirilmiş Terapi Yaklaşımları:</strong> Her çocuğun ve gencin bireysel ihtiyaçlarına uygun terapi yöntemleri kullanılır.
                  </Typography>
                  <Typography component="li">
                    <strong>Aile Katılımı:</strong> Aileler, çocuklarının ihtiyaçlarını ve yaşadıkları zorlukları daha iyi anlamalarına yardımcı olacak bilgiler ve stratejiler
                    öğrenirler.
                  </Typography>
                  <Typography component="li">
                    <strong>Duygusal ve Sosyal Becerilerin Geliştirilmesi:</strong> Çocukların ve gençlerin duygusal ve sosyal becerilerini geliştirmelerine yardımcı olunur,
                    böylece zorluklarla daha iyi başa çıkabilirler.
                  </Typography>
                </Box>

                <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '1rem' }}>
                  Sağlıklı Bir Gelecek İçin Yatırım
                </Typography>
                <Typography variant="body1" sx={{ marginBottom: '1.5rem' }}>
                  Çocuk ve ergen terapisi, sadece mevcut sorunları çözmekle kalmaz, aynı zamanda gelecekte karşılaşılabilecek zorluklarla başa çıkma yeteneklerini de güçlendirir.
                  Unutmayın, çocuklar ve gençler için atılan bu adımlar, onların sağlıklı ve mutlu bireyler olarak yetişmelerine katkı sağlar.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '1rem' }}>
                Hizmetlerimiz
              </Typography>
              <List sx={{ backgroundColor: '#fafafa', padding: '1rem', borderRadius: '4px' }}>
                <ListItem button component="a" href="/bireysel-psikolojik-danisma">
                  <ListItemText primary="Bireysel Psikolojik Danışma" />
                </ListItem>
                <ListItem button component="a" href="/cift-ve-aile-danismanligi">
                  <ListItemText primary="Aile ve Evlilik Danışmanlığı" />
                </ListItem>
                <ListItem button component="a" href="/ebeveyn-danismanligi">
                  <ListItemText primary="Ebeveyn Danışmanlığı" />
                </ListItem>
                <ListItem button component="a" href="/sinav-danismanligi">
                  <ListItemText primary="Sınav Danışmanlığı" />
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </Box>
  );
};

export default ChildTherapy;
