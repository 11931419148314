import React from 'react';
import { Box, Grid, Typography, List, ListItem, ListItemText, Paper } from '@mui/material';

const CoupleTherapy = () => {
  return (
    <Box>
      <Box sx={{ backgroundColor: '#cccccc', padding: '20px 0', marginTop: '100px', textAlign: 'center' }}>
        <Typography
          variant="h1"
          sx={{
            color: '#fff',
            margin: '0px',
            fontSize: '35px',
            letterSpacing: '10px',
            textTransform: 'uppercase',
            marginTop: '70px',
            fontWeight: 100
          }}
        >
          AİLE VE EVLİLİK DANIŞMANLIĞI
        </Typography>
      </Box>

      <Box sx={{ backgroundColor: '#f5f5f5', padding: '2rem' }}>
        <Paper sx={{ padding: '2rem', maxWidth: '1800px', margin: '0 auto', boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)' }}>
          <Grid container spacing={4}>
            <Grid item xs={12} md={9}>
              <img src="/images/çift.jpg" alt="Aile ve Evlilik Danışmanlığı" style={{ width: '100%', borderRadius: '4px', marginBottom: '1rem' }} />
              <Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: '1rem' }}>
                Aile ve Evlilik Terapisi: Sağlıklı İlişkilerin Anahtarı
              </Typography>

              <Typography variant="body1" sx={{ marginBottom: '1.5rem' }}>
                Evlilik ve aile, hayatın en temel yapı taşlarından biridir. Ancak zaman zaman bu yapılar üzerinde baskılar, çatışmalar ve zorluklar ortaya çıkabilir. İşte tam da bu
                noktada, aile ve evlilik terapisi devreye girer.
              </Typography>

              <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '1rem' }}>
                Aile ve Evlilik Terapisi Nedir?
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: '1.5rem' }}>
                Aile ve evlilik terapisi, çiftlerin ve aile üyelerinin ilişkilerinde yaşadıkları sorunları çözmek, iletişimlerini güçlendirmek ve birbirlerine daha iyi destek
                olabilmelerini sağlamak amacıyla yürütülen bir terapi türüdür. Terapist, çiftlerin ve aile üyelerinin arasındaki dinamikleri analiz eder, olası sorun kaynaklarını
                tespit eder ve bunları çözmek için stratejiler geliştirir.
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: '1.5rem' }}>
                Bu terapi süreci bir ayna işlevi görür; ilişkilerdeki çatışmaları ve iletişim sorunlarını yansıtarak tarafların kendi davranışlarını ve karşı tarafın ihtiyaçlarını
                daha iyi anlamalarını sağlar. Terapist, tarafsız bir bakış açısıyla rehberlik eder ve tarafların birbirlerini daha iyi anlamalarını, empati kurmalarını teşvik eder.
              </Typography>

              <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '1rem' }}>
                Hangi Durumlarda Aile ve Evlilik Terapisine Başvurulmalıdır?
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: '1.5rem' }}>
                Her evlilikte ve ailede zaman zaman zorluklar yaşanabilir. Bu zorluklar bazen geçici olabilirken, bazen de derinleşip ilişkiye zarar verebilir. Aile ve evlilik
                terapisine başvurulması gereken durumlar arasında şunlar yer alır:
              </Typography>

              <Box component="ul" sx={{ paddingLeft: '1.5rem', marginBottom: '1.5rem' }}>
                <Typography component="li" sx={{ marginBottom: '0.5rem' }}>
                  <strong>İletişim Sorunları:</strong> Çiftler arasındaki iletişim kopukluğu, yanlış anlamalar ve çatışmaların artması
                </Typography>
                <Typography component="li" sx={{ marginBottom: '0.5rem' }}>
                  <strong>Sadakatsizlik:</strong> Aldatma gibi ciddi güven sorunları yaşanması
                </Typography>
                <Typography component="li" sx={{ marginBottom: '0.5rem' }}>
                  <strong>Ebeveynlik Zorlukları:</strong> Çocuk yetiştirme konusunda farklı görüşlere sahip olan ebeveynler arasında çıkan anlaşmazlıklar
                </Typography>
                <Typography component="li" sx={{ marginBottom: '0.5rem' }}>
                  <strong>Finansal Problemler:</strong> Aile bütçesi üzerinde anlaşmazlık
                </Typography>
                <Typography component="li" sx={{ marginBottom: '0.5rem' }}>
                  <strong>Cinsel Sorunlar:</strong> Cinsel hayattaki uyumsuzluklar ve problemler
                </Typography>
                <Typography component="li" sx={{ marginBottom: '0.5rem' }}>
                  <strong>Yaşam Değişiklikleri:</strong> Taşınma, iş değişikliği, çocuk sahibi olma gibi büyük değişiklikler sonrasında yaşanan adaptasyon sorunları
                </Typography>
              </Box>

              <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '1rem' }}>
                Terapiden Neler Bekleyebilirsiniz?
              </Typography>

              <Box component="ul" sx={{ paddingLeft: '1.5rem', marginBottom: '1.5rem' }}>
                <Typography component="li" sx={{ marginBottom: '0.5rem' }}>
                  <strong>Güvenli Bir Ortam:</strong> Her iki taraf da duygularını, düşüncelerini ve endişelerini açıkça ifade edebilir.
                </Typography>
                <Typography component="li" sx={{ marginBottom: '0.5rem' }}>
                  <strong>İletişim Becerilerinin Geliştirilmesi:</strong> Dinleme, empati ve kendini ifade etme konularında beceriler kazanılır.
                </Typography>
                <Typography component="li" sx={{ marginBottom: '0.5rem' }}>
                  <strong>Farkındalık Kazanma:</strong> Bireylerin kendi davranışlarını ve bu davranışların ilişkiye olan etkilerini fark eder.
                </Typography>
                <Typography component="li" sx={{ marginBottom: '0.5rem' }}>
                  <strong>Çözüm Odaklı Yaklaşım:</strong> Sorunlar üzerinde durulsa da, esas hedef bu sorunları nasıl çözeceğiniz üzerinde çalışmaktır. Terapist, bu süreçte size
                  rehberlik eder.
                </Typography>
                <Typography component="li" sx={{ marginBottom: '0.5rem' }}>
                  <strong>Duygusal Destek:</strong> Zor zamanlarda duygusal olarak desteklenmek, terapinin en önemli faydalarından biridir.
                </Typography>
              </Box>

              <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '1rem' }}>
                Aile ve Evlilik Terapisi İlişkiniz İçin Bir Yatırımdır
              </Typography>

              <Typography variant="body1" sx={{ marginBottom: '1.5rem' }}>
                Zamanla biriken ve büyüyen sorunlar, terapinin yardımıyla çözülerek, ilişkinizin yeniden sağlıklı bir zemine oturmasını sağlayabilir. Her ne kadar ilk adımı atmak
                zorlayıcı gibi görünse de, bu süreçte kazanacağınız farkındalık ve beceriler, hem kendinize hem de ailenize olan katkılarınızı artıracaktır.
              </Typography>

              <Typography variant="body1" sx={{ marginBottom: '1.5rem' }}>
                Unutmayın, her ilişkinin zaman zaman desteğe ihtiyacı olabilir. Aile ve evlilik terapisi, bu desteği profesyonel bir şekilde almanızı sağlar ve daha sağlıklı, mutlu
                bir ilişki sürdürmenize yardımcı olur.
              </Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '1rem' }}>
                Hizmetlerimiz
              </Typography>
              <List sx={{ backgroundColor: '#fafafa', padding: '1rem', borderRadius: '4px' }}>
                <ListItem button component="a" href="/bireysel-psikolojik-danisma">
                  <ListItemText primary="Bireysel Psikolojik Danışma" />
                </ListItem>
                <ListItem button component="a" href="/cocuk-ergen-danismanligi">
                  <ListItemText primary="Çocuk & Ergen Danışmanlığı" />
                </ListItem>
                <ListItem button component="a" href="/ebeveyn-danismanligi">
                  <ListItemText primary="Ebeveyn Danışmanlığı" />
                </ListItem>
                <ListItem button component="a" href="/sinav-danismanligi">
                  <ListItemText primary="Sınav Danışmanlığı" />
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </Box>
  );
};

export default CoupleTherapy;
