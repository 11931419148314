const contactInfo = {
  phone: '0 (532) 492 23 43',
  email: 'psk.dan.polenozudogru@gmail.com'
};

const subjects = [
  { value: 0, label: 'Bireysel Psikolojik Danışma' },
  { value: 1, label: 'Çocuk & Ergen Danışmanlığı' },
  { value: 2, label: 'Aile ve Evlilik Danışmanlığı' },
  { value: 3, label: 'Ebeveyn Danışmanlığı' },
  { value: 4, label: 'Sınav Danışmanlığı' }
];

export { contactInfo, subjects };
