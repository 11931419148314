import React from 'react';
import { Box, Grid, Typography, List, ListItem, ListItemText, Paper } from '@mui/material';

const ParentsTherapy = () => {
  return (
    <Box>
      <Box sx={{ backgroundColor: '#cccccc', padding: '20px 0', marginTop: '100px', textAlign: 'center' }}>
        <Typography
          variant="h1"
          sx={{
            color: '#fff',
            margin: '0px',
            fontSize: '35px',
            letterSpacing: '10px',
            textTransform: 'uppercase',
            marginTop: '70px',
            fontWeight: 100
          }}
        >
          EBVEYN DANIŞMANLIĞI
        </Typography>
      </Box>

      <Box sx={{ backgroundColor: '#f5f5f5', padding: '2rem' }}>
        <Paper sx={{ padding: '2rem', maxWidth: '1800px', margin: '0 auto', boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)' }}>
          <Grid container spacing={4}>
            <Grid item xs={12} md={9}>
              <img src="/images/ebeveyn.png" alt="Ebeveyn Danışmanlığı" style={{ width: '100%', borderRadius: '4px', marginBottom: '1rem' }} />
              <Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: '1rem' }}>
                Ebeveyn Danışmanlığı: Çocuklarınızla Sağlıklı İlişkiler Kurmanın Yolu
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: '1.5rem' }}>
                Ebeveyn olmak, hayatın en zorlu ama aynı zamanda en ödüllendirici rollerinden biridir. Ancak bu rol her zaman kolay değildir. Çocuk yetiştirmek, sabır, bilgi ve
                anlayış gerektirir. Zaman zaman ebeveynler, çocuklarının gelişiminde veya davranışlarında karşılaştıkları zorluklar nedeniyle kendilerini çaresiz hissedebilirler.
                İşte bu noktada ebeveyn danışmanlığı devreye girer.
              </Typography>

              <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '1rem' }}>
                Ebeveyn Danışmanlığı Nedir?
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: '1.5rem' }}>
                Ebeveyn danışmanlığı, ebeveynlerin çocuklarıyla olan ilişkilerini iyileştirmelerine ve daha sağlıklı bir aile dinamiği oluşturmalarına yardımcı olmak amacıyla
                sunulan profesyonel bir hizmettir. Danışmanlık sürecinde ebeveynler, çocuklarıyla nasıl daha etkili iletişim kurabileceklerini, karşılaştıkları sorunları nasıl
                çözebileceklerini ve çocuklarının gelişim sürecini nasıl destekleyebileceklerini öğrenirler.
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: '1.5rem' }}>
                Danışman, ebeveynlerin çocuklarının ihtiyaçlarını ve davranışlarını daha iyi anlamalarına yardımcı olur. Bu süreç yalnızca sorunları çözmekle kalmaz, aynı zamanda
                ebeveynlerin çocuklarıyla daha güçlü ve sağlıklı bir bağ kurmalarını sağlar. Ebeveyn danışmanlığı aile içinde yaşanan gerilimleri hafifletir, ebeveynlik
                becerilerini geliştirir ve uzun vadede hem ebeveynlerin hem de çocukların daha mutlu olmasına katkıda bulunur.
              </Typography>

              <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '1rem' }}>
                Hangi Durumlarda Ebeveyn Danışmanlığına Başvurulmalıdır?
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: '1.5rem' }}>
                Ebeveynlik süreci her zaman düz bir yol değildir. Zaman zaman karşımıza engeller çıkabilir ve bu engellerle başa çıkmak için profesyonel yardıma ihtiyaç
                duyabiliriz. Ebeveyn danışmanlığına başvurulması gereken bazı durumlar şunlardır:
              </Typography>
              <Box component="ul" sx={{ paddingLeft: '1.5rem', marginBottom: '1.5rem' }}>
                <Typography component="li">
                  <strong>Davranış Problemleri:</strong> Çocuğunuzda öfke patlamaları, agresif davranışlar veya uyum sorunları gözlemliyorsanız
                </Typography>
                <Typography component="li">
                  <strong>Gelişimsel Zorluklar:</strong> Çocuğunuzun gelişiminde gecikmeler veya zorluklar varsa
                </Typography>
                <Typography component="li">
                  <strong>Ebeveynlik Yaklaşımlarında Farklılıklar:</strong> Eşinizle ebeveynlik konusunda anlaşmazlıklar yaşıyorsanız
                </Typography>
                <Typography component="li">
                  <strong>Çocukla İletişim Sorunları:</strong> Çocuğunuzla etkili bir şekilde iletişim kurmakta zorlanıyorsanız
                </Typography>
                <Typography component="li">
                  <strong>Ebeveynlik Stresinin Yönetimi:</strong> Ebeveynlik görevlerinin getirdiği stresle başa çıkmakta zorlanıyorsanız
                </Typography>
              </Box>

              <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '1rem' }}>
                Danışmanlık Sürecinde Neler Beklenmelidir?
              </Typography>
              <Box component="ul" sx={{ paddingLeft: '1.5rem', marginBottom: '1.5rem' }}>
                <Typography component="li">
                  <strong>Güvenli Bir Ortam:</strong> Ebeveynler, duygularını ve endişelerini açıkça ifade edebilecekleri güvenli bir ortamda danışmanlık alırlar.
                </Typography>
                <Typography component="li">
                  <strong>Kapsamlı Bir Değerlendirme:</strong> Ailenizin ihtiyaçlarını ve çocuğunuzun gelişimsel durumunu değerlendirmek için kapsamlı bir analiz yapılır.
                </Typography>
                <Typography component="li">
                  <strong>Özelleştirilmiş Stratejiler:</strong> Aile yapınıza ve çocuğunuzun özel ihtiyaçlarına uygun stratejiler geliştirilir.
                </Typography>
                <Typography component="li">
                  <strong>Pratik İpuçları:</strong> Ebeveynlere, evde uygulanabilecek pratik ipuçları ve teknikler sunulur.
                </Typography>
                <Typography component="li">
                  <strong>Destekleyici Yaklaşım:</strong> Danışman, süreç boyunca size rehberlik eder ve moral desteği sağlar.
                </Typography>
              </Box>

              <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '1rem' }}>
                Aile İçi İlişkileri Güçlendirmek İçin Bir Adım
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: '1.5rem' }}>
                Bu süreçte edineceğiniz bilgi ve beceriler sadece mevcut sorunları çözmekle kalmaz, aynı zamanda gelecekte karşılaşabileceğiniz zorluklarla başa çıkma yeteneğinizi
                de geliştirir. Unutmayın, ebeveynlik yolculuğu boyunca desteğe ihtiyaç duymanız normaldir. Ebeveyn danışmanlığı, bu desteği profesyonel bir şekilde almanıza ve
                ailenizi daha güçlü bir temele oturtmanıza yardımcı olur.
              </Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '1rem' }}>
                Hizmetlerimiz
              </Typography>
              <List sx={{ backgroundColor: '#fafafa', padding: '1rem', borderRadius: '4px' }}>
                <ListItem button component="a" href="/bireysel-psikolojik-danisma">
                  <ListItemText primary="Bireysel Psikolojik Danışma" />
                </ListItem>
                <ListItem button component="a" href="/cocuk-ergen-danismanligi">
                  <ListItemText primary="Çocuk & Ergen Danışmanlığı" />
                </ListItem>
                <ListItem button component="a" href="/cift-ve-aile-danismanligi">
                  <ListItemText primary="Aile ve Evlilik Danışmanlığı" />
                </ListItem>
                <ListItem button component="a" href="/sinav-danismanligi">
                  <ListItemText primary="Sınav Danışmanlığı" />
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </Box>
  );
};

export default ParentsTherapy;
